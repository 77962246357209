import { Subscription, timer } from 'rxjs';

import { Component, HostListener, OnDestroy, ViewChild } from '@angular/core';

import { Icons } from '@shared/enums';
import { SettingsModalComponent } from '@/settings/settings-modal/settings-modal.component';
import { SettingsPinModalComponent } from '@/settings/settings-pin-modal/settings-pin-modal.component';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { StatisticCollector } from '@services/statistic-collector/statistic-collector.service';
import { AwMenuInterface } from '@aw-hospital/aw-components-lib/src/interfaces';
import { AwMenuComponent } from '@aw-hospital/aw-components-lib';
import { Store } from '@ngrx/store';
import {
    closeAllModal,
    IActiveModal,
    openModal,
    updateSettingModalStatus,
} from '@/shared/storage';
import { SYSTEM_COMMANDS_TYPES } from '@/shared/constants/system-command';
import { selectIsSettingsPinEnabled } from '@/shared/storage/selectors/config.selectors';
import { IpcService } from '@/shared/services/ipc';
import { SessionService } from '@/shared/services/session';

@Component({
    selector: 'menu',
    templateUrl: './menu.component.html',
})
export class MenuComponent implements OnDestroy {
    public group: AwMenuInterface[] = [
        {
            items: [
                {
                    content: 'Settings',
                    value: 'settings',
                },
                {
                    content: 'Restart',
                    value: 'restart',
                },
                {
                    content:
                        'Turn Off <i class="material-icons right  power-menu">power_settings_new</i>',
                    value: 'shutdown',
                    customHtml: true,
                },
            ],
        },
    ];

    public isTVKit = false;

    private _subscriptions: Subscription[] = [];
    @ViewChild(AwMenuComponent) child: AwMenuComponent;
    private _isSettingsPinEnabled: boolean;

    @HostListener('document:click', ['$event'])
    pageClickHandler($event) {
        if (this.child && this.child.clickOutside) {
            this.child.clickOutside($event);
        }
    }

    constructor(
        private _ipcService: IpcService,
        private _sessionService: SessionService,
        private _staticCollector: StatisticCollector,
        private _store: Store
    ) {
        this._store.select(selectIsSettingsPinEnabled).subscribe((val) => {
            this._isSettingsPinEnabled = val;
        });

        this.checkIsTVKit();

        this._listenRemoteUnlockSettingsEvent();
    }

    public checkIsTVKit() {
        this.isTVKit = this._sessionService.isTVKit;
    }

    private _listenRemoteUnlockSettingsEvent() {
        this._subscriptions.push(
            this._ipcService
                .listener(SYSTEM_COMMANDS_TYPES.UNLOCK_SETTINGS)
                .subscribe(() => {
                    this._store.dispatch(closeAllModal());
                    this.openSettingsModal();
                })
        );
    }

    onSelect(value: string): void {
        this.child.closeMenu();
        const menuTimerSubscription = timer(500).subscribe(() => {
            if (this.child) {
                this.child.selectedItems = [];
            }
        });
        this._subscriptions.push(menuTimerSubscription);
        let modal: IActiveModal = null;

        switch (value) {
            case 'settings':
                this._staticCollector.saveEvent(
                    'Settings_General',
                    'View Settings',
                    'View Settings via header menu item Settings'
                );

                if (this._isSettingsPinEnabled) {
                    modal = {
                        componentName: SettingsPinModalComponent.dialogName,
                        config: {
                            panelClass: [
                                'aw-modal',
                                'settings-pin',
                                'modal-fixed-footer',
                            ],
                        },
                        afterClosed: (confirm: boolean) => {
                            /* istanbul ignore next */
                            if (confirm) {
                                this.openSettingsModal();
                            }
                        },
                    } as IActiveModal;
                    this._store.dispatch(openModal({ modal }));
                    return;
                }

                this.openSettingsModal();
                break;
            case 'restart':
                this._staticCollector.saveEvent(
                    'Settings_General',
                    'Initiate restart device',
                    'Initiate restart device via header menu item Restart'
                );

                modal = {
                    componentName: ConfirmModalComponent.dialogName,
                    config: {
                        panelClass: ['aw-modal', 'modal-md'],
                        data: {
                            title: 'Restart',
                            icon: Icons.Restart,
                            body: '<p>Are you sure you would like to Restart the device?</p>',
                        },
                    },
                    afterClosed: (confirm: boolean) => {
                        /* istanbul ignore next */
                        if (confirm) {
                            this._ipcService.requestReboot();

                            this._staticCollector.saveEvent(
                                'Settings_General',
                                'Restart device',
                                'Restart device via button Restart_Restart modal dialog'
                            );
                        } else {
                            this._staticCollector.saveEvent(
                                'Settings_General',
                                'Cancel restart device',
                                'Cancel restart device via button Cancel_Restart modal dialog'
                            );
                        }
                    },
                } as IActiveModal;
                this._store.dispatch(openModal({ modal }));

                break;
            case 'shutdown':
                this._staticCollector.saveEvent(
                    'Settings_General',
                    'Initiate turn off device',
                    'Initiate turn off device via header menu item Turn off'
                );

                modal = {
                    componentName: ConfirmModalComponent.dialogName,
                    config: {
                        panelClass: ['aw-modal', 'modal-md'],
                        data: {
                            title: 'Turn Off',
                            icon: Icons.Shutdown,
                            body: '<p>Are you sure you would like to Turn Off the device?</p>',
                        },
                    },
                    afterClosed: (confirm: boolean) => {
                        /* istanbul ignore next */
                        if (confirm) {
                            this._ipcService.shutdownDevice();
                            this._staticCollector.saveEvent(
                                'Settings_General',
                                'Turn off device',
                                'Turn off device via button Turn off_Turn off modal dialog'
                            );
                        } else {
                            this._staticCollector.saveEvent(
                                'Settings_General',
                                'Cancel turn off device',
                                'Cancel turn off device via button Cancel_Turn off modal dialog'
                            );
                        }
                    },
                } as IActiveModal;
                this._store.dispatch(openModal({ modal }));
                break;
            default:
        }
    }

    openSettingsModal(): void {
        const modal = {
            componentName: SettingsModalComponent.dialogName,
            config: {
                panelClass: [
                    'aw-modal',
                    'settings-pin',
                    'modal-fixed-footer',
                    'modal-fixed',
                ],
            },
        } as IActiveModal;
        this._store.dispatch(openModal({ modal }));
        this._store.dispatch(
            updateSettingModalStatus({ isOpenSettingModal: true })
        );
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach((subscription: Subscription) => {
            if (subscription && subscription.unsubscribe) {
                subscription.unsubscribe();
            }
        });
    }
}
