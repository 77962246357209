import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    AwButtonModule,
    AwDatePickerModule,
    AwExpansionModule,
    AwKeyboardModule,
    AwIndicatorModule,
    AwInputModule,
    AwMenuModule,
    AwRadioModule,
    AwRatingModule,
    AwSelectModule,
    AwSliderModule,
    AwSoundMeterModule,
    AwSpinnerModule,
    AwSwitchModule,
    AwTabsModule,
    AwTextareaModule,
    AwTimePickerModule,
    AwCheckboxModule,
    AwNotificationsCarouselModule,
    AwInputPhoneModule,
    AwPanelModule,
} from '@aw-hospital/aw-components-lib';
import {
    BatteryManagerComponent,
    PinCodeComponent,
    StickyFooterComponent,
    BatteryDetailsComponent,
    BatteryLevelComponent,
} from './components';
import { DebounceClickDirective } from '@/directives';
import { CallerComponent } from '@/shared/components/caller/caller.component';

const amwellModules = [
    // Amwell Components
    AwButtonModule,
    AwDatePickerModule,
    AwExpansionModule,
    AwKeyboardModule,
    AwIndicatorModule,
    AwInputModule,
    AwInputPhoneModule,
    AwMenuModule,
    AwRadioModule,
    AwRatingModule,
    AwSelectModule,
    AwSliderModule,
    AwSoundMeterModule,
    AwSpinnerModule,
    AwSwitchModule,
    AwTabsModule,
    AwTextareaModule,
    AwTimePickerModule,
    AwCheckboxModule,
    AwNotificationsCarouselModule,
    AwPanelModule,
    FormsModule,
    ReactiveFormsModule,
];
@NgModule({
    declarations: [
        BatteryManagerComponent,
        CallerComponent,
        StickyFooterComponent,
        PinCodeComponent,
        DebounceClickDirective,
        BatteryDetailsComponent,
        BatteryLevelComponent,
    ],
    imports: [...amwellModules, CommonModule],
    exports: [
        ...amwellModules,
        BatteryManagerComponent,
        CallerComponent,
        StickyFooterComponent,
        CommonModule,
        PinCodeComponent,
        DebounceClickDirective,
        BatteryDetailsComponent,
        BatteryLevelComponent,
    ],
    providers: [{ provide: Window, useValue: window }],
})
export class SharedModule {}
