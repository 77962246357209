import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
    selector: 'app-battery-level',
    templateUrl: './battery-level.component.html',
})
export class BatteryLevelComponent implements OnInit, OnChanges {
    @Input() level: number = null;
    @Input() acPowerConnected = false;
    @Input() kits: number = null;

    batteryClass = 'icon_battery';
    pathClassRequired = false;
    roundedLvl: number;

    ngOnInit(): void {
        this.updateData();
    }
    ngOnChanges() {
        this.batteryClass = 'icon_battery';
        this.updateData();
    }

    updateData(): void {
        this.roundedLvl = Math.round(Math.round(this.level) / 5) * 5;

        if (this.level === null || this.level === undefined) {
            this.batteryClass = 'icon_battery-0 gray-mid-text';
        } else {
            if (this.acPowerConnected) {
                this.batteryClass += '-charging';
            }
            if (this.roundedLvl === 100) {
                this.batteryClass += '-full';
            } else {
                this.batteryClass += `-${this.roundedLvl}`;
            }
            if (this.roundedLvl >= 70) {
                this.batteryClass += ' success-text';
            } else if (this.roundedLvl >= 30) {
                this.batteryClass += ' warning-text';
            } else {
                this.batteryClass += ' danger-text';
            }
        }
        if (this.roundedLvl < 100) {
            this.batteryClass += ' layered';
            this.pathClassRequired = true;
        }
        if (this.acPowerConnected && this.kits === 0) {
            this.batteryClass = 'icon_battery-charging-0 gray-mid-text';
        }
    }
}
