import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map } from 'rxjs/operators';
import * as fromActions from './video-info.actions';
import * as fromEndpointConfigActions from '@/shared/storage/endpoint-config/endpoint-config.actions';

@Injectable({ providedIn: 'root' })
export class VideoInfoEffects {
    constructor(private _actions$: Actions) {}

    switchCamera$ = createEffect(() =>
        this._actions$.pipe(
            ofType(fromActions.switchCameraAction),
            filter(({ switchCamera }) => !!switchCamera),
            map(({ switchCamera }) =>
                fromEndpointConfigActions.updateEndpointConfig({
                    key: 'VideoInput',
                    value: switchCamera,
                    skipSettings: true,
                })
            )
        )
    );
}
