import { ERROR_MESSAGES, NETWORK_ERRORS } from '@/shared/constants';
import { IEndpointConfiguration } from '@/shared/interfaces';
import { Queue } from '@/shared/queue/queue';
import {
    selectIsAuthenticated,
    selectIsFleetConnected,
    selectIsInternetConnected,
} from '@/shared/storage/selectors';
import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    combineLatest,
    interval,
    Subscription,
    throwError,
    TimeoutError,
    timer,
} from 'rxjs';
import {
    take,
    takeWhile,
    timeout,
    distinctUntilChanged,
    catchError,
    filter,
} from 'rxjs/operators';
import { LoggerService } from '../logger/logger.service';
import { SessionService } from '../session';
import { EndpointTypes } from '@shared/enums';

@Injectable({ providedIn: 'root' })
export class MetricService implements OnDestroy {
    // TODO can be deleted -> no used
    _isInitialized = false;
    _messageQueue = new Queue();
    config: IEndpointConfiguration;
    private _continue = true;
    private _alive = true;
    private _subscriptions: Subscription[] = [];
    private _subscriptionsInit: Subscription[] = [];
    private _isFleetApiOnline: boolean;
    private _isAuthenticated: boolean;
    private _isInternetConnected: boolean;
    private _prevErr: string;

    constructor(
        private _http: HttpClient,
        private _sessionService: SessionService,
        private _loggerService: LoggerService,
        private _store: Store
    ) {
        this._subscriptions.push(
            combineLatest([
                this._store.select(selectIsAuthenticated),
                this._store.select(selectIsFleetConnected),
                this._store.select(selectIsInternetConnected),
            ])
                .pipe(distinctUntilChanged())
                .subscribe(
                    ([
                        isAuthenticated,
                        isFleetConnected,
                        isInternetConnected,
                    ]) => {
                        this._isAuthenticated = isAuthenticated;
                        this._isFleetApiOnline = isFleetConnected;
                        this._isInternetConnected = isInternetConnected;

                        this._isAuthenticated &&
                        this._isFleetApiOnline &&
                        this._isInternetConnected
                            ? !this._continue &&
                              timer(0).subscribe(() => this.startQueue())
                            : this.pauseQueue();
                    }
                )
        );
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach((s) => s && s.unsubscribe());
        this._alive = false;
    }

    startQueue(): void {
        this._continue = true;
        this.init();
    }

    pauseQueue(): void {
        this._continue = false;
    }

    enqueue(item: any): void {
        this._messageQueue.sendMessageQueue(item);
    }

    sendDefaultMetrics(): void {
        let metrics = {
            call_state: +false,
            mute_state: +false,
            codec_registration: +true,
            endpoint_serial_number:
                this._sessionService.deviceInfo.endpointSerialNumber,
            os_version: this._sessionService.deviceInfo.osVersion,
            app_version: this._sessionService.deviceInfo.appVersion,
            application_update_status: 'IDLE',
            os_update_status: 'IDLE',
            ac_power_connected: 1,
        };

        if (
            this._sessionService.getConfig().endpoint_type_id ===
            EndpointTypes.Intmtvkit
        ) {
            metrics = {
                ...metrics,
                ...{
                    battery_connected: 0,
                    battery: 0,
                },
            };
        }

        this.sendMetrics(metrics);
    }

    init(): void {
        if (this._continue) {
            this.sendDefaultMetrics();
            const observable$ = this._messageQueue.getMessageQueue();
            const metricsArray = [];
            this._subscriptionsInit.forEach((e) => !!e && e.unsubscribe());
            this._subscriptionsInit = [];

            this._subscriptionsInit.push(
                observable$
                    .pipe(
                        takeWhile(() => this._alive),
                        filter(() => this._continue)
                    )
                    .subscribe((metricObj) => {
                        metricsArray.push(metricObj);
                    })
            );
            this._subscriptionsInit.push(
                interval(3000)
                    .pipe(
                        takeWhile(() => this._alive),
                        filter(() => this._continue && metricsArray.length > 0)
                    )
                    .subscribe(() => this.sendMetrics(metricsArray.shift()))
            );
        }
    }

    sendMetrics(deviceMetrics, isMetric: boolean = false): void {
        if (deviceMetrics && !!this._sessionService.deviceId) {
            deviceMetrics = Object.assign(
                { device_id: this._sessionService.deviceId },
                deviceMetrics
            );
            if (isMetric) {
                this._loggerService.log('MetricToSent', deviceMetrics);
            }

            this._http
                .post(
                    `${this._sessionService.fleetUrl}${'v1/metrics/send'}`,
                    deviceMetrics,
                    {
                        responseType: 'json',
                    }
                )
                .pipe(
                    take(1),
                    timeout(1000 * 60 * 2),
                    catchError((error) => {
                        // Handle timeout
                        if (error instanceof TimeoutError) {
                            return throwError({
                                statusText: ERROR_MESSAGES.TIMEOUT_ERROR,
                            });
                        }
                        // Return other errors
                        return throwError(error);
                    })
                )
                .subscribe({
                    error: (sendMetricsErr) => {
                        const {
                            status = '',
                            statusText,
                            error,
                        } = sendMetricsErr;
                        const stringifiedErr = JSON.stringify(sendMetricsErr);

                        if (
                            this._prevErr !== stringifiedErr &&
                            statusText !== ERROR_MESSAGES.TIMEOUT_ERROR &&
                            statusText !== ERROR_MESSAGES.UNKNOWN_ERROR &&
                            status !== 0 &&
                            status !== 401
                        ) {
                            this._loggerService.error(
                                'sendMetrics:error',
                                sendMetricsErr
                            );
                        }

                        this._prevErr = stringifiedErr;

                        if (
                            statusText === ERROR_MESSAGES.HTTP_ERROR_NO_INTERNET
                        ) {
                            if (
                                this._isAuthenticated &&
                                this._isInternetConnected &&
                                this._isFleetApiOnline
                            ) {
                                this.startQueue();
                            } else {
                                this.pauseQueue();
                            }
                        } else {
                            if (error && NETWORK_ERRORS.includes(error)) {
                                timer(30000).subscribe(() => {
                                    this.retrySendMetrics(deviceMetrics);
                                });
                            }
                        }
                    },
                });
        }
    }

    retrySendMetrics(deviceMetrics): void {
        if (!deviceMetrics.retry) {
            this._loggerService.info(
                'metrics.sendMetrics:retry',
                'Retrying sendMetrics'
            );
            deviceMetrics.retry = true;
            this.enqueue(deviceMetrics);
        }
    }
}
