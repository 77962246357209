import { ActionReducerMap } from '@ngrx/store';
import {
    IBatteryStatus,
    IDeviceInfo,
    IEndpointConfiguration,
    IEndpointSettings,
    IMediaDevices,
    IVideoCall,
    IWaitingRoomState,
} from './shared/interfaces';
import * as fromAuthReducer from './shared/storage/auth/auth.reducer';
import * as fromBatteryReducer from './shared/storage/battery/battery.reducer';
import * as fromDeviceReducer from './shared/storage/device/device-reducer';
import * as fromEndpointConfigReducer from './shared/storage/endpoint-config/endpoint-config.reducer';
import * as fromNetworkReducer from './shared/storage/network/network.reducer';
import * as fromMediaDevicesReducer from './shared/storage/media-devices/media-devices.reducers';
import * as fromWaitingRoomReducer from './shared/storage/waiting-room/waiting-room-reducer';
import * as fromEndpointReducer from './shared/storage/endpoint/endpoint-reducer';
import * as fromLayoutStateReducer from './shared/storage/layout/layout.reducer';
import * as fromVideoInfoReducer from './shared/storage/video-info/video-info.reducers';
import * as fromFeccPositionReducer from './shared/storage/fecc-position/fecc-position.reducer';
import { ILayoutState } from './shared/storage/layout/layout.reducer';
import { IFeccPosition } from './shared/interfaces/fecc-position/fecc-position.interface';

export interface AppState {
    auth: fromAuthReducer.IAuthState;
    battery: IBatteryStatus;
    device: IDeviceInfo;
    endpointConfig: IEndpointConfiguration;
    mediaDevices: IMediaDevices;
    network: fromNetworkReducer.INetworkState;
    waitingRoom: IWaitingRoomState;
    endpoint: IEndpointSettings;
    layout: ILayoutState;
    video: IVideoCall;
    feccPosition: { [key: string]: IFeccPosition };
}

export const appReducer: ActionReducerMap<AppState> = {
    auth: fromAuthReducer.AuthReducer,
    battery: fromBatteryReducer.BatteryReducer,
    endpointConfig: fromEndpointConfigReducer.EndpointConfigReducer,
    device: fromDeviceReducer.DeviceInfoReducer,
    mediaDevices: fromMediaDevicesReducer.mediaDevicesReducer,
    network: fromNetworkReducer.NetworkReducer,
    waitingRoom: fromWaitingRoomReducer.WaitingRoomReducer,
    endpoint: fromEndpointReducer.EndpointReducer,
    layout: fromLayoutStateReducer.LayoutReducer,
    video: fromVideoInfoReducer.VideoInfoReducer,
    feccPosition: fromFeccPositionReducer.FeccPoisitionReducer,
};
