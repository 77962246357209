import { throwError } from 'rxjs';
import { SecondsEnum } from '@/shared/enums/seconds.enum';

/**
 * angular.json -> "builder": "@angular-devkit/build-angular:browser", -> scripts: [...]
 * {
 *       "bundleName": "lazy-aviziartc",
 *       "inject": false,
 *       "input": "src/libs/aviziartc.js"
 *   },
 *   {
 *       "bundleName": "lazy-webrtc_v23pexip",
 *       "inject": false,
 *       "input": "src/libs/webrtc_v23pexip.js"
 *   }
 */

enum LazyLoadScriptNames {
    WebRtcV23PeXip = 'lazy-webrtc_v23pexip.js',
    AWSDK = 'lazy-awsdk.js',
}

/**
 * angular.json -> "builder": "@angular-devkit/build-angular:browser", -> styles: [...]
 * "src/assets/scss/style.scss",
 *   {
 *       "bundleName": "lazy-video-plugin-tpc-style",
 *       "inject": false,
 *       "input": "node_modules/@aw/video-plugin-tpc/styles"
 *   }
 */

export class ThirdPartyLoader {
    static loadedScriptsList = [];
    static loadingScriptList = [];
    private loadScriptAndAppendToBody(scriptUrl: string): Promise<any | null> {
        if (this.isScriptLoaded(scriptUrl)) {
            return Promise.resolve();
        }
        if (this.isScriptLoading(scriptUrl)) {
            return this.fireWhenLoaded(scriptUrl);
        }
        this.trackLoadingScript(scriptUrl);
        return new Promise((resolve) => {
            const scriptElement = document.createElement('script');
            scriptElement.src = scriptUrl;
            scriptElement.defer = true;
            scriptElement.async = true;
            scriptElement.type = 'text/javascript';
            scriptElement.onload = (event) => {
                this.trackLoadedScript(scriptUrl);
                resolve(event);
            };
            document.body.appendChild(scriptElement);
        });
    }

    private fireWhenLoaded(scriptUrl: string): Promise<any | null> {
        let counter = 0;
        return new Promise((resolve, reject) => {
            const interval = setInterval(() => {
                if (this.isScriptLoaded(scriptUrl)) {
                    clearInterval(interval);
                    resolve(null);
                    return;
                }
                counter++;
                if (counter > 500) {
                    clearInterval(interval);
                    reject('Too long waiting of script loading ' + scriptUrl);
                }
            }, 50);
        });
    }

    private trackLoadingScript(scriptUrl: string) {
        ThirdPartyLoader.loadingScriptList.push(scriptUrl);
    }

    private trackLoadedScript(scriptUrl: string) {
        ThirdPartyLoader.loadingScriptList =
            ThirdPartyLoader.loadingScriptList.filter(
                (link) => link !== scriptUrl
            );
        ThirdPartyLoader.loadedScriptsList.push(scriptUrl);
    }

    public isScriptLoading(scriptUrl: string): boolean {
        return ThirdPartyLoader.loadingScriptList.includes(scriptUrl);
    }

    public isScriptLoaded(scriptUrl: string): boolean {
        return ThirdPartyLoader.loadedScriptsList.includes(scriptUrl);
    }

    private async initLazyScriptsLoading(): Promise<void> {
        try {
            await this.loadScriptAndAppendToBody(
                LazyLoadScriptNames.WebRtcV23PeXip
            );
        } catch (error) {
            throwError(
                new Error(`Unable to load third-party scripts, error: ${error}`)
            );
        }
    }

    public initScriptLoadingWithOneSecDelay(): void {
        setTimeout(() => this.initLazyScriptsLoading(), SecondsEnum.One);
    }

    public initAWSDKLoading(
        callback = () => null,
        delay = SecondsEnum.One
    ): void {
        setTimeout(async () => {
            try {
                await this.loadScriptAndAppendToBody(
                    LazyLoadScriptNames.AWSDK
                ).then(() => callback());
            } catch (error) {
                throwError(
                    new Error(
                        `Unable to load third-party scripts, error: ${error}`
                    )
                );
            }
        }, delay);
    }
}
