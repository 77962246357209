<div class="empty-dash-screen overflow-hidden pb4 primary h100">
    <div class="header-content-main flex justify-between items-center white py1">
        <div>
            <endpoint-photo></endpoint-photo>
        </div>
        <div class="flex items-center m-r3">
            <battery-manager [enableDetails]="true"></battery-manager>
            <network-indicator></network-indicator>
            <menu class="m-y0"></menu>
        </div>
    </div>

    <div class="flex items-center justify-center w100 visible h100 flex-column">
        <div class="white px35 pt25 pb25 text-center login-white rel flex flex-column">
            <h1 class="m-b2" *ngIf="endpointName$ | async as endpointName">{{ endpointName }}</h1>
            <h6 class="gray-mid-text m-b0">is ready to receive a call</h6>
            <div class="flex justify-center items-center w100 bottom0 left0 charge-block p0 m-t2">
                <span>
                    <span class="path1"></span>
                    <span class="path2"></span>
                </span>
                <span class="m-b0 m-l2 flex gray-mid-text items-center" *ngIf="hasBattery$ | async">
                    <span><battery-manager [showPercentage]="false"></battery-manager></span>
                    <span class="m-l2"><h4 class="m-b0">Battery Charge</h4></span>
                    <span class="m-t1 m-l2"><battery-manager [showBatteryIcon]="false"></battery-manager></span>
                </span>
            </div>
        </div>
    </div>
</div>
