/* eslint-disable prefer-const */
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import {
    catchError,
    concatMap,
    delay,
    distinctUntilChanged,
    filter,
    finalize,
    first,
    retryWhen,
    tap,
    timeout,
} from 'rxjs/operators';
import {
    combineLatest,
    of,
    Subscription,
    throwError,
    TimeoutError,
    timer,
} from 'rxjs';
import { Store } from '@ngrx/store';
import { StorageService } from '@services/storage/storage.service';
import { SessionService } from '@services/session/session.service';
import { LoggerService } from '@services/logger/logger.service';
import { IpcService } from '../ipc/ipc.service';
import { PTZAxisService } from '../ptz/ptz-axis.service';
import { PTZService } from '../ptz/ptz.service';
import { SYSTEM_COMMANDS_TYPES } from '@/shared/constants/system-command';
import { UpdaterHandlerService } from '../updater/update-handler.service';
import { ERROR_MESSAGES } from '@/shared/constants';
import {
    selectIsInternetConnected,
    selectIsFleetConnected,
    selectIsAuthenticated,
} from '@/shared/storage/selectors';
import { ReceiveCallService } from '../receive-call/receive-call.service';
import { authSetIsFleetConnected } from '@/shared/storage/auth/auth.actions';
import { navigateToMain } from '@/shared/storage/layout/layout.actions';

@Injectable({
    providedIn: 'root',
})
export class FleetHandlerService implements OnDestroy {
    _previousCommand: any;
    private _fleetSubscriber: Subscription;
    private _fleetApiStatusSubscriber: Subscription;
    // private _fleetApiTimerSubscriber: Subscription;
    private _fleetApiCheckTimeout: number = 30 * 1000;
    private _isFleetApiOnline: boolean;
    private _isAuthenticated: boolean;
    private _isInternetConnected: boolean;
    private _prevErr: string;
    private _isOnErrorGenericPage = false;

    constructor(
        private _router: Router,
        private _store: Store,
        private _storageService: StorageService,
        private _sessionService: SessionService,
        private _loggerService: LoggerService,
        private _ipcService: IpcService,
        private _http: HttpClient,
        private _ptzAxisService: PTZAxisService,
        private _ptzService: PTZService,
        private _updateHandlerService: UpdaterHandlerService,
        private _receiveCallService: ReceiveCallService
    ) {}

    handlers = {
        update_config: (): void => this.updateConfig(),
        update_app: () => this._updateHandlerService.checkForAppUpdate(),
        update_os: () => this._updateHandlerService.checkForOsUpdate(),
        shutdown_device: () => this._ipcService.shutdownDevice(),
        restart_device: () => this._ipcService.restartDevice(),
        restart_app: () => this._ipcService.restartApp(),
        restart_browser: () => this._ipcService.restartBrowser(),
        tv_power_on: () => this._ipcService.setTVTurnOn(),
        tv_power_off: () => this._ipcService.setTVTurnOff(),
        telehealth_input: () => this._ipcService.switchToPrimarySource(),
        previous_tv_mode: () => this._ipcService.returnToPreviousSource(),
        led_turn_off: () => this._ipcService.setLEDTurnOff(),
        unlock_screen: () =>
            this._ipcService.notify(SYSTEM_COMMANDS_TYPES.UNLOCK_SCREEN),
        unlock_settings: () =>
            this._ipcService.notify(SYSTEM_COMMANDS_TYPES.UNLOCK_SETTINGS),
        set_camera_home_position: () =>
            this._ptzAxisService.setCameraHomePosition(),
        switch_device_camera: (fleetCommand) =>
            this._storageService.switchCamera(
                fleetCommand.parameter.video_input
            ),
        led_turn_blue: () => this._ipcService.setLEDTurnOn('BLUE'),
        factory_reset: async () => this._ipcService.requestFactoryDefault(),
        make_call_observer: (fleetCommand) =>
            this._receiveCallService.receiveObserverCall(fleetCommand),
        make_call_interactive: (fleetCommand) =>
            this._receiveCallService.receiveInteractiveCall(fleetCommand),
        make_call: (fleetCommand) =>
            this._receiveCallService.receiveCall(fleetCommand),
        cancel_case: (data) =>
            this._ipcService.notify(SYSTEM_COMMANDS_TYPES.CANCEL_CASE, data),
        audio_control: (fleetCommand) => this.changeGain(fleetCommand),
        ptz: (fleetCommand) => this.setPTZ(fleetCommand),
        prod_mode: () => this._ipcService.setToProdMode(),
        dev_mode: () => this._ipcService.setToDevMode(),
        ipc_command: (fleetCommand) =>
            this._ipcService.makeIpcRequest(fleetCommand.ipc_message),
        restart_camera: () => this.remoteRestartCamera(),
        create_user: () => this.remoteCreateUser(),
        remove_user: () => this.remoteDeleteUser(),
    };

    public init(): void {
        this.setupTimerForFleetApiStatus();
        this.setupLongPollingForFleet();
        this.longPoll();
    }

    ngOnDestroy(): void {
        // this._fleetApiTimerSubscriber &&
        //     this._fleetApiTimerSubscriber.unsubscribe();
        this._fleetApiStatusSubscriber &&
            this._fleetApiStatusSubscriber.unsubscribe();
        this._fleetSubscriber && this._fleetSubscriber.unsubscribe();
    }
    /* istanbul ignore next */
    private setupTimerForFleetApiStatus() {
        const retryCount = 2;
        const retryWaitMilliSeconds = 5000;
        let retryInProgess = false;

        combineLatest([
            timer(0, this._fleetApiCheckTimeout),
            this._store.select(selectIsInternetConnected),
        ])
            .pipe(
                filter(() => !retryInProgess),
                tap(() => (retryInProgess = true))
            )
            .subscribe(() => {
                this._http
                    .get(this._sessionService.fleetUrl, {
                        responseType: 'text',
                    })
                    .pipe(
                        timeout(25000),
                        first(),
                        retryWhen((error) =>
                            error.pipe(
                                concatMap((error, count) => {
                                    if (count < retryCount) {
                                        return of(error);
                                    }
                                    return throwError(error);
                                }),
                                delay(retryWaitMilliSeconds)
                            )
                        ),
                        finalize(() => {
                            retryInProgess = false;
                        })
                    )
                    .subscribe(
                        () => {
                            this._store.dispatch(
                                authSetIsFleetConnected({
                                    isFleetConnected: true,
                                })
                            );
                            if (this._isOnErrorGenericPage) {
                                this._isOnErrorGenericPage = false;
                                this._store.dispatch(navigateToMain());
                            }
                        },
                        () => {
                            this._ipcService
                                .isOnline({
                                    component: 'Fleet Handler Service',
                                    message:
                                        'CHECK_INTERNET_BEFORE_FLEET_NOT_REACHABLE_ERROR',
                                })
                                .pipe(first())
                                .subscribe((connected) => {
                                    if (!connected?.success) {
                                        return;
                                    }
                                    this._store.dispatch(
                                        authSetIsFleetConnected({
                                            isFleetConnected: false,
                                        })
                                    );
                                    this._loggerService.error(
                                        'fleet.apiStatus',
                                        ERROR_MESSAGES.FLEET_NOT_REACHABLE
                                    );
                                    this._router.navigateByUrl(
                                        '/error-generic',
                                        {
                                            state: {
                                                errorType:
                                                    ERROR_MESSAGES.AMWELL_SERVICE_NOT_REACHABLE,
                                            },
                                        }
                                    );
                                    this._isOnErrorGenericPage = true;
                                });
                        }
                    );
            });
    }

    private setupLongPollingForFleet() {
        this._fleetApiStatusSubscriber &&
            this._fleetApiStatusSubscriber.unsubscribe();
        this._fleetApiStatusSubscriber = combineLatest([
            this._store.select(selectIsAuthenticated),
            this._store.select(selectIsFleetConnected),
            this._store.select(selectIsInternetConnected),
        ])
            .pipe(distinctUntilChanged())
            .subscribe(
                ([isAuthenticated, isFleetConnected, isInternetConnected]) => {
                    this._isFleetApiOnline = isFleetConnected;
                    this._isAuthenticated = isAuthenticated;
                    this._isInternetConnected = isInternetConnected;

                    this._isAuthenticated &&
                    this._isFleetApiOnline &&
                    this._isInternetConnected
                        ? this.longPoll()
                        : this._fleetSubscriber &&
                          this._fleetSubscriber.unsubscribe();
                }
            );
    }
    /* istanbul ignore next */
    longPoll(): void {
        this._fleetSubscriber && this._fleetSubscriber.unsubscribe();

        this._fleetSubscriber = this._http
            .post(
                `${this._sessionService.fleetUrl}v1/commands`,
                {
                    device_id: this._sessionService.deviceId,
                },
                {
                    responseType: 'json',
                }
            )
            .pipe(
                timeout(this._sessionService.enableFleetTimeout ? 5000 : 70000),
                catchError((error) => {
                    // Handle timeout
                    if (error instanceof TimeoutError) {
                        return throwError({
                            statusText: ERROR_MESSAGES.TIMEOUT_ERROR,
                        });
                    }
                    // Return other errors
                    return throwError(error);
                })
            )
            .subscribe(
                (data: any) => {
                    console.log('........fleet data coming', data);
                    this.processFleetCommand(data);
                    this.longPoll();
                },
                (err) => {
                    const { status, statusText } = err;
                    const stringifiedErr = JSON.stringify(err);
                    if (
                        this._prevErr !== stringifiedErr &&
                        statusText !== ERROR_MESSAGES.TIMEOUT_ERROR &&
                        statusText !== ERROR_MESSAGES.UNKNOWN_ERROR &&
                        status !== 0 &&
                        status !== 401
                    ) {
                        this._loggerService.error('fleet:longPoll', err);
                    }
                    this._prevErr = stringifiedErr;

                    if (statusText === ERROR_MESSAGES.HTTP_ERROR_NO_INTERNET) {
                        this._store
                            .select(selectIsInternetConnected)
                            .pipe(first())
                            .subscribe((isInternetConnected) => {
                                if (
                                    this._isAuthenticated &&
                                    isInternetConnected &&
                                    this._isFleetApiOnline
                                ) {
                                    this.longPoll();
                                }
                            });
                    } else if (
                        this._isAuthenticated &&
                        this._isFleetApiOnline
                    ) {
                        this.longPoll();
                    }
                }
            );
    }

    abort(): void {
        if (this._fleetSubscriber) {
            this._fleetSubscriber.unsubscribe();
        }
    }

    sendCommand(data: any, url: string): void {
        data.device_id = this._sessionService.deviceId;

        this._http
            .post(`${this._sessionService.fleetUrl}${url}`, data, {
                responseType: 'json',
            })
            .pipe(
                catchError((error) => {
                    let errorMsg;
                    if (error.error instanceof ErrorEvent) {
                        errorMsg = `Error: ${error.error.message}`;
                    } else {
                        errorMsg = `Error: ${error.message}`;
                    }
                    this._loggerService.error('fleet:sendCommand', errorMsg);
                    return of([]);
                })
            )
            .subscribe((data) => data);
    }

    processFleetCommand(data: any): void {
        if (!data.command) {
            return;
        }
        this._loggerService.info('fleet:processcommand', data.command);
        if (
            data.command.command_text &&
            (data.command.command_text === 'dev_mode' ||
                data.command.command_text === 'prod_mode')
        ) {
            this.handleFleetCommand(data.command);
            return;
        }

        // avoid duplicate commands except ptz
        if (
            this._previousCommand &&
            this._previousCommand.command === data.command &&
            data.command.indexOf('ptz') < 0
        ) {
            return;
        }

        if (data.command.indexOf('ptz') >= 0) {
            data.command = JSON.stringify({
                command_text: 'ptz',
                ptzValue: data.command,
            });
        }
        const fleetCommand = JSON.parse(data.command);
        this.handleFleetCommand(fleetCommand);

        this._previousCommand = data;

        timer(3000).subscribe(() => (this._previousCommand = null));
    }

    handleFleetCommand(fleetCommand: any): void {
        const func = this.handlers[fleetCommand.command_text];
        if (typeof func === 'function') {
            func(fleetCommand);
        }
    }

    updateConfig(): void {
        this._storageService.refreshEndpointConfig();
    }

    changeGain(fleetCommand): void {
        const data = fleetCommand.parameter.audio_control;
        switch (data.command) {
            case 'volume':
                data.type === 'speaker'
                    ? this._sessionService.deviceInstance.setSpeakerGain({
                          volume: data.volume,
                      })
                    : this._sessionService.deviceInstance.setMicGain({
                          volume: data.volume,
                      });
                break;
            // TODO: Looks like not needed
            // case 'mute':
            //     this._sessionService.deviceInstance.setMute(
            //         data.type,
            //         data.mute
            //     );
            //     break;
        }
    }

    setPTZ(fleetCommand): void {
        if (this._sessionService.deviceInstance.type === 'intmtvkit') {
            if (this._sessionService.deviceInstance.feccCameraSelected) {
                this.setPanTiltAndZoom(fleetCommand);
            } else {
                const ptzValues = fleetCommand.ptzValue.split(',');
                if (ptzValues.length === 6) {
                    //Pant Tilt
                    this._ptzAxisService.move(ptzValues[5]);
                } else if (ptzValues.length === 5) {
                    //Click to move
                    this._ptzAxisService.movePosition(ptzValues);
                } else if (ptzValues.length === 4) {
                    //zoom
                    this._ptzAxisService.zoom(ptzValues[3]);
                }
            }
        } else {
            this.setPanTiltAndZoom(fleetCommand);
        }
    }

    setPanTiltAndZoom(fleetCommand): void {
        const [, pan, tilt, zoom] = fleetCommand.ptzValue
            .split(',')
            .map(Number);

        this._ptzService.absolutePanTiltZoom(pan, tilt, zoom);
    }

    remoteRestartCamera() {
        this._ptzAxisService.restart();
    }

    remoteCreateUser() {
        this._ptzAxisService.createTeleHealthUser();
    }

    remoteDeleteUser() {
        this._ptzAxisService.deleteTeleHealthUser();
    }
}
