import {
    Output,
    Component,
    Input,
    OnDestroy,
    EventEmitter,
    ChangeDetectorRef,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { IBatteryStatus } from '../../interfaces';
import { selectBatteryState } from '../../storage/selectors';

@Component({
    selector: 'app-battery-details',
    templateUrl: './battery-details.component.html',
    styleUrls: ['./battery-details.component.scss'],
})
export class BatteryDetailsComponent implements OnDestroy {
    @Input() hidden = true;
    @Output() onHide: EventEmitter<void> = new EventEmitter();

    batteryStatus: IBatteryStatus;

    maxBatteryKits = 6;

    batteries: { id: number; level: number; acPowerConnected: boolean }[] = [];

    private _subscriptions: Subscription[] = [];

    constructor(private _store: Store, private _cdr: ChangeDetectorRef) {
        this.updateBatteryPercentage();
    }

    updateBatteryPercentage(): void {
        this._subscriptions.push(
            this._store
                .select(selectBatteryState)
                .subscribe((batteryState: IBatteryStatus) => {
                    this.batteryStatus = batteryState;
                    this.mapBatteriesKits();
                })
        );
    }

    mapBatteriesKits() {
        if (!this.batteryStatus) return;
        this.batteries = new Array(this.maxBatteryKits).fill({
            level: null,
        });
        this.batteries = this.batteries.map((_, index) => {
            const kit = this.batteryStatus.kits.find(
                (each) => each.id === index + 1
            );
            return {
                id: kit ? kit.id : index + 1,
                level: kit ? kit.level : null,
                acPowerConnected: kit ? kit.acPowerConnected : false,
            };
        });
        this.batteries.sort((a, b) => a.id - b.id);
        this._cdr.markForCheck();
    }

    hide() {
        this.hidden = true;
        this.onHide.emit();
    }

    updateBatteryClass(level, acPowerConnected) {
        let batteryClass = 'icon_battery';
        let pathClassRequired = false;

        if (acPowerConnected) {
            batteryClass += '-charging';
        }
        const roundedLvl = Math.round(Math.round(level) / 5) * 5;

        if (roundedLvl >= 95) {
            batteryClass += '-95';
        } else {
            batteryClass += `-${roundedLvl}`;
        }

        if (roundedLvl >= 70) {
            batteryClass += ' success-text';
        } else if (roundedLvl >= 30) {
            batteryClass += ' warning-text';
        } else {
            batteryClass += ' danger-text';
        }

        if (roundedLvl < 100) {
            batteryClass += ' layered';
            pathClassRequired = true;
        }

        return {
            batteryClass,
            pathClassRequired,
            level: `${level}%`,
            full_no_charge: roundedLvl === 100 && !acPowerConnected,
        };
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach(
            (subscription: Subscription) =>
                subscription && subscription.unsubscribe()
        );
    }
}
