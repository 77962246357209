import { DEFAULT_DIALOGS_PANEL_CLASS } from '@/core/components/dialogs/dialogs-static.data';
import { IpcService } from '@/shared/services/ipc';
import { SessionService } from '@/shared/services/session';
import { TrackEventDataKeys } from '@/shared/services/statistic-collector/static-collector-defined-data';
import { StorageService } from '@/shared/services/storage';
import { setPtzFeccPositionPrivacy } from '@/shared/storage';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmModalComponent } from '@core/components/confirm-modal/confirm-modal.component';
import { RollbackDialogComponent } from '@core/components/dialogs/rollback-dialog/rollback-dialog.component';
import { EndpointTypes, Icons } from '@enums';
import { IDeviceInfo } from '@interfaces/device/device-info.interface';
import { Store } from '@ngrx/store';
import { StatisticCollector } from '@services/statistic-collector/statistic-collector.service';
import {
    IEndpointConfiguration,
    IEndpointSettings,
    IEnterpriseSettings,
} from '@shared/interfaces';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-general-tab',
    styles: [
        `
            :host {
                display: flex;
            }
        `,
    ],
    templateUrl: './general-tab.component.html',
})
export class GeneralTabComponent implements OnInit, OnDestroy {
    public endpoint: IEndpointSettings;
    public config: IEndpointConfiguration;
    public enterprise: IEnterpriseSettings;
    public deviceInfo: IDeviceInfo;
    public hardware = {
        title: '',
        icon: '',
        deviceSerialNumber: '',
        firmwareVersion: '',
    };
    public worksurfaceLightsValues = [
        { friendly: 'Automatic', pwData: 'surf_lights_automatic' },
        { friendly: 'Always On', pwData: 'surf_lights_always_on' },
    ];
    public displaySleepOptions: { view: string; value: string }[] = [];
    public osVersion = '';
    public logo = 'assets/images/Amwell-Colored-Logo.svg';
    private _subscriptions: Subscription[] = [];

    constructor(
        public dialogRef: MatDialogRef<ConfirmModalComponent>,
        private _sessionService: SessionService,
        private _matDialog: MatDialog,
        private _ipcService: IpcService,
        private _storageService: StorageService,
        private _staticCollector: StatisticCollector,
        private _store: Store
    ) {
        this.endpoint = this._sessionService.getEndpoint();
        this.config = this._sessionService.getConfig();
        this.enterprise = this._sessionService.getEnterprise();
        this.defineHardware(this.endpoint);
        this._subscriptions.push(
            this._storageService.subscribeOnDeviceInfo(
                (response: IDeviceInfo) => {
                    this.deviceInfo = response;
                    const { osVersion } = this.deviceInfo || {};
                    if (!osVersion) {
                        return;
                    }
                    this.osVersion = osVersion;
                }
            )
        );

        this._subscriptions.push(
            this._storageService.subscribeOnConfigChange(
                (config: IEndpointConfiguration) => {
                    this.config = config;
                }
            ) as Subscription
        );
    }

    ngOnInit(): void {
        this.displaySleepOptions = new Array(...Array(61).keys())
            .slice(1)
            .map((item) => ({
                view: `${item} ${item === 1 ? 'minute' : 'minutes'}`,
                value: item,
            }));
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach(
            (subscription: Subscription) =>
                subscription && subscription?.unsubscribe()
        );
    }

    updateConfigSettings(key: string, value: string | number | boolean): void {
        this._storageService.updateEndpointConfig(key, value, false);

        switch (key) {
            case 'auto_answer':
                this._staticCollector.saveEvent(
                    'Settings_General',
                    'Turn on Auto answer',
                    'Turn on Auto answer via switch On_Settings modal dialog'
                );
                break;
            case 'onscreen_keyboard':
                this._staticCollector.saveEvent(
                    ' Settings_General',
                    'Turn on On screen keyboard',
                    'Turn on On screen keyboard via switch On_Settings modal dialog'
                );

                break;
            case 'camera_privacy_mode':
                const [sm, cap] = !!value ? ['on', 'On'] : ['off', 'Off'];

                this._staticCollector.saveEvent(
                    'Settings_General',
                    `Turn ${sm} camera privacy mode`,
                    `Turn ${sm} camera privacy mode via switch ${cap}_Settings modal dialog`
                );

                this._store.dispatch(setPtzFeccPositionPrivacy());
                break;
            default:
                this._staticCollector.saveEvent(
                    'Settings_General',
                    'Turn off On screen keyboard',
                    'Turn off On screen keyboard via switch Off_Settings modal dialog'
                );
                break;
        }
    }

    defineHardware(endpoint: IEndpointSettings): void {
        if (!endpoint) {
            return;
        }
        switch (endpoint.endpoint_type_id) {
            case 'APGARCODEC':
                this.hardware.title = 'Amwell C250';
                this.hardware.icon =
                    'icon icon-full-cart-with-ptz-camera-w-software-black';
                break;
            case 'INTMTVKIT':
                this.hardware.title = 'Hospital TV Kit 100';
                this.hardware.icon = 'icon icon-television-app-v-2-black';
                break;
            case EndpointTypes.C500:
                this.hardware.deviceSerialNumber =
                    this._sessionService.deviceSerialNumber;
                this.hardware.firmwareVersion =
                    this._sessionService.firmwareVersion;
                this.hardware.title = 'Amwell C500';
                this.hardware.icon = 'icon icon-ripley-with-ptz-camera-black';
                break;
            default:
                this.hardware.title = 'UNKNOWN';
                this.hardware.icon = 'icon icon-other-device-black';
                break;
        }
    }

    showConfirmModal(): void {
        this.dialogRef = this._matDialog.open(ConfirmModalComponent, {
            panelClass: ['aw-modal', 'modal-md'],
            data: {
                title: 'Reset',
                icon: Icons.Reset,
                confirmText: 'Factory Default',
                body: `<p>This will deactivate your device and delete settings such
                     as network credentials and audio/video device preferences.
                     If you proceed, you will need assistance from your IT admin to reactivate your device.</p>
                     <p>Are you sure you would like to reset the device?</p>`,
            },
        });
        this._subscriptions.push(
            this.dialogRef.afterClosed().subscribe((confirm: boolean) => {
                if (confirm) {
                    this.factoryDefault();

                    // lib.device.removeQrCode();
                    // lib.system.enqueue(lib.constants.DELETE_ALL_NETWORK_COMMAND);

                    // // command supported on os version higher than 1.3.3
                    // if (compareVersions.compare(deviceInfo.osVersion, '1.3.3', '>')  === true) {
                    //     lib.system.enqueue(lib.constants.FACTORY_DEFAULT_COMMAND);
                    // }
                    // setTimeout(() => lib.system.enqueue(lib.constants.REBOOT_COMMAND), 10000);
                    // break;
                    this._staticCollector.saveEvent(
                        'Settings_General',
                        'Back to Factory default',
                        'Back to Factory default via button Factory default_Settings modal dialog'
                    );
                }
            })
        );
    }

    private factoryDefault() {
        this._ipcService.requestFactoryDefault();
    }

    openRollbackDialog(): void {
        this._staticCollector.trackEvent(TrackEventDataKeys.DialogOpenRollback);
        this._matDialog.open(
            RollbackDialogComponent,
            DEFAULT_DIALOGS_PANEL_CLASS
        );
    }

    changeExpansion(event): void {
        switch (event) {
            case 'advanced':
                this._staticCollector.saveEvent(
                    'Settings_General',
                    'View Advanced settings',
                    'View Advanced settings via icon Arrow_Settings modal dialog'
                );
                break;
            case 'legal-information':
                this._staticCollector.saveEvent(
                    'Settings_General',
                    'View Legal information',
                    'View Legal information via icon Arrow_Settings modal dialog'
                );
                break;
            case 'rollback':
                this._staticCollector.saveEvent(
                    'Settings_General',
                    'View Rollback information',
                    'View Rollback information via icon Arrow_Settings modal dialog'
                );
                break;
            default:
                break;
        }
    }

    onChangeSleepTime(item: number): void {
        const key = 'turn_off_display_timeout';
        this.updateConfigSettings(key, item);
    }
}
