import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectEndpointPhotoUrl } from '@/shared/storage/selectors/endpoint.selectors';

@Component({
    selector: 'endpoint-photo',
    template: `<img
        data-pw="header_logo"
        *ngIf="endpointPhotoUrl$ | async as photoSrc"
        class="header-logo m-l3 flex items-center"
        [src]="photoSrc"
    />`,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EndpointPhotoComponent {
    public endpointPhotoUrl$: Observable<string | null> = this._store.select(
        selectEndpointPhotoUrl
    );
    constructor(private _store: Store) {}
}
