import { StatisticCollector } from '@/shared/services/statistic-collector/statistic-collector.service';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TrackEventDataKeys } from '@/shared/services/statistic-collector/static-collector-defined-data';
import { DeviceService } from '@/shared/services/device';

@Component({
    selector: 'app-restart-dialog',
    template: `
        <div class="sized-modal">
            <div class="modal-heading no-border">
                <h3 class="sized-title m-b0">Restart</h3>
            </div>
            <div class="modal-content">
                <p class="sized-txt">
                    Are you sure you would like to Restart the device?
                </p>
            </div>
            <div class="modal-footer flex justify-end pb2">
                <aw-button
                    styleClasses="btn m-r2 btn-simple btn-large waves-effect waves-light"
                    (onClick)="closeModal()"
                    >Cancel</aw-button
                >
                <aw-button
                    styleClasses="waves-effect waves-light btn btn-danger modal-trigger btn-large"
                    (onClick)="restart()"
                >
                    Restart
                </aw-button>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RestartDialogComponent {
    constructor(
        private _matDialogRef: MatDialogRef<RestartDialogComponent>,
        private _statisticCollector: StatisticCollector,
        private _deviceService: DeviceService
    ) {}

    public closeModal(): void {
        this._statisticCollector.trackEvent(
            TrackEventDataKeys.CancelRestartDevice
        );
        this._matDialogRef.close();
    }

    public restart(): void {
        this._statisticCollector.trackEvent(
            TrackEventDataKeys.InitRestartDevice
        );
        this._deviceService.restart();
        this._matDialogRef.close();
    }
}
