<section class="caller-info flex flex-column items-center flex-auto justify-center" *ngIf="callerInfo && callerTitle">
    <div class="circle avatar-caller m-b4" data-pw="call_provider_photo">
        <div class="ripple-wave">
            <div class="ripple-wave-2"></div>
        </div>
        <img class="circle" alt="profilePicture" src="{{ callerInfo.profilePicture }}" />
    </div>
    <h1 class="m-b2 center white-text">
        {{ callerTitle }}
        <span class="m-t2" *ngIf="notAnswerLabel">Did Not Answer</span>
    </h1>
    <h3 class="m-b2 center" *ngIf="callerInfo.organization_name" data-pw="call_provider_name">
        {{ callerInfo.organization_name }}
    </h3>
</section>
