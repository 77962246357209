import { Injectable } from '@angular/core';
import { cameraChanged } from '@shared/storage/ptz/ptz.actions';
import find from 'lodash/find';
import { LoggerService } from '../logger/logger.service';

import { SYSTEM_COMMANDS_TYPES } from '@/shared/constants/system-command';
import { selectVideoInputsOrder } from '@/shared/storage/selectors/union.selectors';
import { FECC_DEVICES } from '@constants';
import { FeccPosition } from '@enums';
import { Store } from '@ngrx/store';
import { Observable, of, Subject, throwError } from 'rxjs';
import { first, switchMap, tap } from 'rxjs/operators';
import { IpcService } from '../ipc/ipc.service';

export interface IPanTiltZoom {
    pan: number;
    tilt: number;
    zoom: number;
}

@Injectable({ providedIn: 'root' })
export class PTZService {
    private _camera;
    private _capabilities;
    private _feccCamera;
    private _zoom = 0;

    public panTiltZoom$: Subject<IPanTiltZoom> = new Subject();
    public panTiltZoom = {
        pan: 0,
        tilt: 0,
        zoom: 0,
    } as IPanTiltZoom;

    constructor(
        private _loggerService: LoggerService,
        private _ipcService: IpcService,
        private _store: Store
    ) {
        this.init().subscribe();
        this._ipcService
            .listener(SYSTEM_COMMANDS_TYPES.FACTORY_DEFAULT)
            .pipe(first())
            .subscribe(() => {
                // reset selected camera position to home
                this.setFeccPosition(FeccPosition.Home);
            });
    }

    init(): Observable<any> {
        return this._ipcService.discoverPtzDevices().pipe(
            first(),
            tap(({ camera_list: devices = [] }) => {
                this._loggerService.info(
                    'ptz:v4l:init',
                    JSON.stringify(devices)
                );
            }),
            switchMap(({ camera_list: devices = [] }) => {
                if (devices.length === 0) {
                    return of(null);
                }

                let ptzDevice: { path: string };

                let device = null;
                selectVideoInputsOrder.find(
                    (name) =>
                        (device = devices.find(
                            (camera) =>
                                camera.name
                                    .toLowerCase()
                                    .indexOf(name.toLowerCase()) >= 0
                        ))
                );

                this._feccCamera = !device
                    ? this._feccCamera
                    : FECC_DEVICES.find(
                          (d) =>
                              device.name
                                  .toLowerCase()
                                  .indexOf(d.v4lName.toLowerCase()) > -1
                      );

                if (this._feccCamera) {
                    ptzDevice = device;
                }

                if (!device) {
                    for (const device of devices) {
                        this._feccCamera = FECC_DEVICES.find(
                            (d) =>
                                device.name
                                    .toLowerCase()
                                    .indexOf(d.v4lName.toLowerCase()) > -1
                        );

                        if (this._feccCamera) {
                            ptzDevice = device;
                            // check other FECC cameras if it needs to override EPTZ Camera
                            if (this._feccCamera.name !== 'EPTZ Camera') {
                                break;
                            }
                        }
                    }
                }
                if (!ptzDevice) {
                    this._camera = undefined;
                    this._capabilities = undefined;
                    return throwError('no v4l camera found');
                }
                this._camera = ptzDevice;
                return this._ipcService
                    .getPTZCapabilities(ptzDevice.path)
                    .pipe(first());
            }),
            tap((capabilities) => {
                this._capabilities = capabilities;
            })
        );
    }

    absolutePanTiltZoom(pan: number, tilt: number, zoom: number): void {
        if (!this._camera) {
            this._loggerService.error(
                'ptz:v4l:absolutePanTilt',
                'Not found camera path | FECC Camera not selected'
            );
            return;
        }
        // pan tilt adjustment needed for fecc movements.
        this._ipcService
            .setAbsolutePanTiltZoom(
                pan * 3600,
                tilt * 3600,
                !!zoom || zoom === 0 ? zoom : this._zoom,
                this._camera.path
            )
            .pipe(first())
            .subscribe(() => {
                this._ipcService
                    .getPTZCurrenntPosition(this._camera.path)
                    .pipe(first())
                    .subscribe((currPosition: any) => {
                        this._zoom = currPosition.zoom;
                    });
            });
        this.panTiltZoom = { pan, tilt, zoom };
        this.panTiltZoom$.next(this.panTiltZoom);
    }

    setFeccPosition(position: FeccPosition): void {
        if (!position || !this._feccCamera) {
            return;
        }

        const ptzValue = this._feccCamera[position];
        this._loggerService.info(
            'ptz:v4l:absolutePanTilt',
            'Setting FECC Camera to ' + position + ' position ' + ptzValue
        );

        const [pan, tilt, zoom] = ptzValue.split(',').map(Number);
        this.absolutePanTiltZoom(pan, tilt, zoom);
    }

    changeCamera(camera, inVideoCall = false): void {
        this._ipcService
            .discoverPtzDevices()
            .pipe(
                first(),
                switchMap(({ camera_list: devices = [] }) => {
                    if (inVideoCall) {
                        camera = FECC_DEVICES.find(
                            (d) =>
                                parseInt(d.productId, 16) ===
                                    parseInt(camera.productId, 16) &&
                                parseInt(d.vendorId, 16) ===
                                    parseInt(camera.vendorId, 16) &&
                                camera.label
                                    .toLowerCase()
                                    .indexOf(d.v4lName.toLowerCase()) > -1
                        );
                    }
                    if (!camera) {
                        return of(null);
                    }

                    const feccCamera = find(devices, (d) => {
                        return (
                            d.name
                                .toLowerCase()
                                .indexOf(camera.v4lName.toLowerCase()) > -1
                        );
                    });
                    if (feccCamera) {
                        feccCamera.privacy = camera.privacy;
                        feccCamera.home = camera.home;
                        this._camera = feccCamera;
                        this._feccCamera = feccCamera;
                    }
                    return feccCamera?.path
                        ? this._ipcService
                              .getPTZCapabilities(feccCamera.path)
                              .pipe(first())
                        : of(null);
                })
            )
            .subscribe((capabilities) => {
                if (inVideoCall) {
                    return;
                }

                this._capabilities = capabilities;
                this._store.dispatch(cameraChanged());
            });
    }
}
