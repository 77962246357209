export const FILE_PATH_OS = {
    QRCODE: '/opt/amwell/config/app/QRCODE',
    DOMAIN: '/opt/amwell/config/app/DOMAIN',
    APP_LOG: '/opt/amwell/log/app/app.log',
    APP_ERROR: '/opt/amwell/log/app/error.log',
    MINRRAY_CAMERA: '/opt/amwell/config/app/minrray_camera_connected',
    //DEFAULT_CAMERA: '/opt/amwell/config/app/default_camera_connected',
    DEVICE_NAME: '/opt/amwell/config/app/DEVICE_NAME',
};
export const VALIDATORS = {
    IP_VALIDATOR:
        '^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$',
    PREFIX_VALIDATOR: '^(3[0-2]|[12][0-9]|[1-9])$',
};
