import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { takeWhile } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import {
    selectScreenSaverPin,
    selectSettingsPin,
    selectShowKeyboard,
} from '@/shared/storage/selectors';
import { StorageService } from '@shared/services/storage';
@Component({
    selector: 'app-pin-code',
    templateUrl: './pin-code.component.html',
})
export class PinCodeComponent implements OnInit, OnDestroy {
    public showError = false;
    public pinInput = '';
    public pin = [];
    private _configuredPinCode: string;
    private _isAlive = true;
    private _timeoutId = null;
    private _isKeyboardDisabled: boolean;

    @ViewChild('keyboardInput', { static: false })
    private _keyboardInput!: ElementRef;

    @Input() isLockScreen: boolean;
    @Output() onSuccess: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() onErrorPin: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output() onFocus: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() onBlur: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        private _store: Store,
        private _storageService: StorageService
    ) {}

    ngOnInit(): void {
        combineLatest([
            this._store.select(selectSettingsPin),
            this._store.select(selectScreenSaverPin),
            this._store.select(selectShowKeyboard),
        ])
            .pipe(takeWhile(() => this._isAlive))
            .subscribe(([settingsPin, lockScreenPin, onScreenKeyboard]) => {
                this._configuredPinCode = this.isLockScreen
                    ? lockScreenPin
                    : settingsPin;
                if (!onScreenKeyboard) {
                    this._isKeyboardDisabled = true;
                    this.changeKeyboardSetting('onscreen_keyboard', true);
                }
                this.setFocus();
            });
    }

    changeKeyboardSetting(key: string, value: boolean): void {
        this._storageService.updateEndpointConfig(key, value, true);
    }

    ngOnDestroy() {
        this._isAlive = false;
        this.setBlur();
        if (this._isKeyboardDisabled === true) {
            this.changeKeyboardSetting('onscreen_keyboard', false);
        }
    }

    setFocus(): void {
        setTimeout(() => {
            this._keyboardInput.nativeElement.focus();
            this._keyboardInput.nativeElement.onblur = () => {
                this.setFocus();
            };

            const button: HTMLElement = document.querySelector(
                'button.keyboard-btn-switch-numbers'
            );

            button?.click();
        });
    }

    setBlur(): void {
        setTimeout(() => {
            this._keyboardInput.nativeElement.blur();
            this.closeKeyboard();
        });
    }

    // TODO: Remove this after aw-keyboard fix (Keyboard doesn't react on blur event)
    closeKeyboard(): void {
        const el = document.createElement('div');
        const body = document.querySelector('body');
        body.appendChild(el);
        el.click();
        body.removeChild(el);
    }

    deleteChar(): void {
        this.pinInput = this.pinInput.slice(0, -1);
        this._keyboardInput.nativeElement.value = this.pinInput;
        this.pin.length = this.pinInput.length;
    }

    pinInputChanged(): void {
        if (this.pinInput.length === this.pin.length + 1) {
            const inputtedCharacter: number = parseInt(
                this.pinInput[this.pinInput.length - 1],
                10
            );
            if (isNaN(inputtedCharacter)) {
                this.deleteChar();
                return;
            }

            this.pin.push(inputtedCharacter);
            this.showError = false;
            if (this.pin.length >= 4) {
                if (this.pin.join('') === this._configuredPinCode) {
                    this.pin = [];
                    this.setBlur();
                    this.onSuccess.emit(true);
                } else {
                    this.pin = [];
                    this.showError = true;
                    this.onErrorPin.emit(false);
                    this.setFocus();
                }
                this.pinInput = '';
            }
            clearTimeout(this._timeoutId);

            this._timeoutId = setTimeout(
                () => {
                    this.showError = false;
                },
                5000,
                false
            );
        } else {
            this.pin.length = this.pinInput.length;
        }
    }
}
