import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-settings-pin-modal',
    templateUrl: './settings-pin-modal.component.html',
})
export class SettingsPinModalComponent {
    public static dialogName = 'SettingPinModalComponent';

    constructor(public dialogRef: MatDialogRef<SettingsPinModalComponent>) {}

    gotoDashboard(event: MouseEvent): void {
        event.stopPropagation();
        this.dialogRef.close();
    }

    handlerSuccess() {
        this.dialogRef.close(true);
    }
}
