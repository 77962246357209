import { IConsumerSettings } from '@/shared/interfaces/settings/consumer-settings.interface';
import { IEnterpriseSettings } from '@/shared/interfaces/settings/enterprise-settings.interface';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CaseService } from '@shared/services/case/case.service';
import { EndPointService } from '@shared/services/endpoint/endpoint.service';
import { LogService } from '@shared/services/log/log.service';
import { ServiceLineService } from '@shared/services/service-line/service-line.service';
import { SessionService } from '@shared/services/session/session.service';
import { navigateToMain } from '@shared/storage/layout/layout.actions';

declare let awsdk;

@Injectable({ providedIn: 'root' })
export class AwsdkService {
    consumerAccount: IConsumerSettings;
    apiKey: string;
    enterprise: IEnterpriseSettings;
    baseUrl: string;
    providerTypesList: any;
    consumer: any;
    practice: any;
    provider: any;
    providerTypes: any;
    visitContext: any;
    states: any;
    transfererProvider: any;
    sdk: any;

    constructor(
        private _router: Router,
        private _endpointService: EndPointService,
        private _logService: LogService,
        private _sessionService: SessionService,
        private _serviceLineService: ServiceLineService,
        private _http: HttpClient,
        private _store: Store,
        private _caseService: CaseService
    ) { }

    init() {
        this.sdk = new awsdk.default.AWSDK();

        this.consumerAccount = this._sessionService.getConsumerAccount();
        const enterprise = this._sessionService.getEnterprise();
        this.apiKey =
            enterprise && enterprise.sdk_key
                ? enterprise.sdk_key
                : 'consumerWebSdkApiKeyTest1';
        this.enterprise = enterprise;
        this.baseUrl =
            enterprise && enterprise.ocg_integration_link
                ? enterprise.ocg_integration_link
                : 'https://iot16.amwellintegration.com';

        return this.sdk
            .initialize({
                sdkApiKey: this.apiKey,
                baseUrl: this.baseUrl,
            })
            .then(() => {
                this.getUSStates();
                return this.sdk.getProviderTypes().then((pt) => {
                    this.providerTypesList = pt;
                });
            })
            .catch((err) => {
                // eslint-disable-next-line no-console
                console.log(err);
                // this.LocalService.sendLogs({
                //     type: 'error',
                //     module: 'AWSDK_SERVICE_INIT_WEB',
                //     details: JSON.stringify(err),
                // });
            });
    }

    authenticate() {
        if (!this.sdk.initialized) {
            return this._logService.logErrorAndRedirect(
                'AwsdkService.authenticate: sdk is not initialized'
            );
        }

        if (this.enterprise.enable_mutual_auth) {
            //Mutual Auth
            return this._endpointService
                .getMutualAuthTokenFromV10Server()
                .subscribe((result) => {
                    return this.sdk.authenticationService
                        .authenticateMutualAuthWithToken(result.token)
                        .then((authenticateResponse) =>
                            this.sdk.consumerService.getConsumer(
                                authenticateResponse
                            )
                        )
                        .then((consumer) => (this.consumer = consumer))
                        .catch((err) => {
                            return this._logService.createErrorMessage(err);
                        });
                });
        } else {
            //Basic Auth with consumer username and password
            return this.sdk.authenticationService
                .authenticateConsumerWithUsername(
                    this.consumerAccount.username,
                    this.consumerAccount.password
                )
                .then((authenticateResponse) =>
                    this.sdk.consumerService.getConsumer(authenticateResponse)
                )
                .then((consumer) => (this.consumer = consumer))
                .catch((err) => {
                    return this._logService.createErrorMessage(err);
                });
        }
    }

    getPractices(practiceSourceId) {
        return this.sdk.practiceService
            .getPractices(this.consumer)
            .then((practiceList) => {
                this.practice = practiceList.practices.find(
                    (pt) => pt.sourceId === practiceSourceId
                );
            });
    }

    async getNewPracticeSearchCriteria(practiceSourceId) {
        return this.sdk.practiceService.getNewPracticeSearchCriteria({
            sourceId: practiceSourceId,
        });
    }

    findPractices(practiceSearchCriteria) {
        return this.sdk.practiceService
            .findPractices(practiceSearchCriteria)
            .then((practice) => {
                if (practice && practice.length > 0) {
                    this.practice = practice[0];
                    return;
                }
                throw new Error('practice not found');
            })
            .catch((err) => {
                this._logService.createErrorMessage(err);
            });
    }

    getOnDemandSpecialities() {
        return this.sdk.practiceService
            .getOnDemandSpecialties(this.consumer, this.practice)
            .then((provider) => {
                this.provider = provider[0];
            })
            .catch((err) => {
                // TODO May we try to detect
                // if issue is noAvailableProviders
                // to redirect user on page /waiting-room/no-provider
                this._logService.createErrorMessage(err);
            });
    }

    assignProvider(selectedProvider, caseId, workflowId) {
        this._endpointService.createProvider(selectedProvider);
        this._caseService.assignCaseWithProvider(
            caseId,
            selectedProvider.sourceId,
            workflowId
        );
    }

    findFirstAvailable(providerTypesFilter, caseId, workflowId) {
        const ptFilterValue =
            this.getProviderTypesFilterValue(providerTypesFilter);
        return (
            this.sdk.visitService
                //this.pracice will fiter the providers by the service line current practice
                .findFirstAvailable(
                    this.visit,
                    null,
                    this.practice,
                    ptFilterValue
                )
                .then(
                    (visit) => {
                        this.provider = visit.firstAvailableData.provider;
                        this.assignProvider(this.provider, caseId, workflowId);
                        this._router.navigateByUrl('/waiting-room/next');
                    },
                    (err) => {
                        // eslint-disable-next-line no-console
                        console.log('Error', err);
                    }
                )
                .catch((err) => {
                    this._logService.createErrorMessage(err);
                })
        );
    }
    visit() {
        throw new Error('Method not implemented.');
    }

    getProviderTypesFilterValue(providerTypesFilter): any {
        let ptFilterValue = [];
        const ptFilterArray = providerTypesFilter.map(
            (item) => item.provider_type_enum
        );

        ptFilterValue = this.providerTypesList.filter((ptn) =>
            ptFilterArray.includes(ptn.__data.resource_key)
        );

        return ptFilterValue;
    }

    getProvidersOnline() {
        this.providerTypes =
            this._serviceLineService.getProviderTypesOriginalData();
        const ptFilterValue = this.getProviderTypesFilterValue(
            this.providerTypes
        );
        return this.sdk.providerService
            .findProviders(
                this.consumer,
                this.practice,
                null,
                null,
                ptFilterValue,
                null,
                null,
                null
            )
            .then((providers) => {
                return providers.filter((provider) => {
                    return (
                        provider.visibility ===
                        awsdk.default.AWSDKProviderVisibility
                            .WEB_AVAILABLE ||
                        provider.visibility ===
                        awsdk.default.AWSDKProviderVisibility.WEB_BUSY
                    );
                });
            })
            .catch((err) => {
                this._logService.createErrorMessage(err);
            });
    }

    getVisitContext() {
        return this.sdk.visitService
            .getVisitContext(this.consumer, this.provider)
            .then((visitContext) => {
                this.visitContext = visitContext;
            })
            .catch((err) => {
                this._logService.createErrorMessage(err);
            });
    }

    cancelVisit() {
        this.sdk.visitService.cancelVisit(this.visit).catch((err) => {
            this._logService.createErrorMessage(err);
        });
    }

    endVisit() {
        return this.sdk.visitService.endVisit(this.visit);
    }

    createVisit(intakeData, sourceId) {
        const consumerOverrideDetails =
            this.transformConsumerOverrideDetailsData(intakeData);

        this.visitContext.sourceId = sourceId;
        this.visitContext.isQuickConnect = true;
        this.visitContext.consumerOverrideDetails = consumerOverrideDetails;
        this.visitContext.otherTopic = intakeData.notes;

        this.visitContext.modalityType = 'VIDEO';

        return this.sdk.visitService
            .createVisit(this.visitContext)
            .then((visit) => {
                this.visit = visit;
            })
            .catch((err) => {
                this._logService.createErrorMessage(err);
            });
    }

    transformConsumerOverrideDetailsData(patientData) {
        const consumerOverrideDetails =
            this.sdk.visitService.newConsumerOverrideDetails();

        consumerOverrideDetails.mrnId = patientData.mrnId || patientData.mrn;

        consumerOverrideDetails.firstName =
            patientData.firstName || patientData.patientFirstName;

        consumerOverrideDetails.lastName =
            patientData.lastName || patientData.patientLastName;

        if (patientData.patientMiddleName) {
            consumerOverrideDetails.middleNameOrInitial =
                patientData.patientMiddleName;
        }

        if (patientData.sex) {
            const genderEnum = awsdk.default.AWSDKGender;
            consumerOverrideDetails.gender =
                patientData.sex.toLowerCase() === 'male'
                    ? genderEnum.MALE
                    : genderEnum.FEMALE;
        }

        if (patientData.email) {
            consumerOverrideDetails.email = patientData.email;
        }

        if (patientData.dob) {
            consumerOverrideDetails.dob = new Date(patientData.dob);
        }

        if (patientData.address1) {
            consumerOverrideDetails.address1 = patientData.address1;
        }

        if (patientData.address2) {
            consumerOverrideDetails.address2 = patientData.address2;
        }

        if (patientData.city) {
            consumerOverrideDetails.city = patientData.city;
        }

        if (patientData.state) {
            consumerOverrideDetails.state = this.states.find((state) => {
                // patientData.region can be either state name or state code, check both
                return (
                    state.name === patientData.state ||
                    state.code === patientData.state
                );
            });
        }

        if (patientData.zipCode) {
            consumerOverrideDetails.zipCode = patientData.zipCode;
        }

        if (patientData.phone) {
            consumerOverrideDetails.phone = patientData.phone;
        }

        return consumerOverrideDetails;
    }

    waitForVisitCostCalculationToFinish() {
        return this.sdk.visitService
            .waitForVisitCostCalculationToFinish(this.visit)
            .catch((err) => {
                this._logService.createErrorMessage(err);
            });
    }

    getCountries() {
        return this.sdk.getCountries();
    }

    getUSStates() {
        return this.sdk.getCountries().then((results) => {
            const countryUS = results.find((item) => item.code === 'US');
            this.states = countryUS.states;
            return countryUS.states;
        });
    }

    waitForProviderToJoinVisit(caseId, workflowId) {
        return this.sdk.visitService
            .waitForProviderToJoinVisit(this.visit)
            .then((visit) => {
                this.visit = visit;
                this.waitForVisitToFinish(caseId, workflowId);
            })
            .catch((err) => {
                this._logService.createErrorMessage(err);
                this.waitForVisitToFinish(caseId, workflowId);
            });
    }

    updateConnectionStatus() {
        this.sdk.visitService.updateConnectionStatus(this.visit);
    }

    startVisit(caseId, workflowId) {
        return this.sdk.visitService
            .startVisit(this.visit)
            .then((visit) => {
                this.visit = visit;

                this.waitForProviderToJoinVisit(caseId, workflowId).then(() => {
                    //ALTM Fix, Issue # APGAR-1420. New SDK breaks cart functionality and does not update
                    // the connection status.  Make changes to update connection status directly.
                    this.sdk.visitService.__updateConnectionStatus(this.visit);
                    console.log('visit started');
                });
            })
            .catch((err) => {
                this._logService.createErrorMessage(err);
                this.waitForProviderToJoinVisit(caseId, workflowId);
            });
    }

    getProviderDetails(provider) {
        return this.sdk.providerService
            .getProviderDetails(provider, this.consumer)
            .then((provider) => (this.provider = provider))
            .catch((err) => {
                this._logService.createErrorMessage(err);
            });
    }

    getSelectedProvider() {
        return this.provider;
    }

    getTransferer() {
        return this.transfererProvider;
    }

    saveConsumer() {
        return this._http.post('v10/registerConsumer', this.newConsumer);
    }
    newConsumer() {
        throw new Error('Method not implemented.');
    }

    waitForVisitToFinish(caseId, workflowId) {
        return this.sdk.visitService
            .waitForVisitToFinish(this.visit)
            .then((visit) => {
                switch (visit.endReason) {
                    case awsdk.default.AWSDKEndReason
                        .ASSISTANT_DECLINE_AND_TRANSFER:
                    case awsdk.default.AWSDKEndReason
                        .PROVIDER_DECLINE_AND_TRANSFER:
                        this.transfererProvider = this.provider;
                        this.provider = visit.providerForTransfer;
                        this.assignProvider(this.provider, caseId, workflowId);
                        this._router.navigateByUrl('/waiting-room/next');
                        break;
                    case awsdk.default.AWSDKEndReason.PROVIDER_BAIL:
                    case awsdk.default.AWSDKEndReason.PROVIDER_DISCONNECT:
                    case awsdk.default.AWSDKEndReason.PROVIDER_LOGOUT:
                    case awsdk.default.AWSDKEndReason.PROVIDER_RESPONSE_TIMEOUT:
                    case awsdk.default.AWSDKEndReason.VISIT_EXPIRED:
                    case awsdk.default.AWSDKEndReason.WAITING_ROOM_EXPIRED:
                    case awsdk.default.AWSDKEndReason.ASSISTANT_DECLINE:
                    case awsdk.default.AWSDKEndReason.PROVIDER_DECLINE:
                        this._router.navigateByUrl('waiting-room/cancelled');
                        break;
                    case awsdk.default.AWSDKEndReason.PROVIDER_END:
                        this._store.dispatch(navigateToMain());
                        break;
                }
            })
            .catch((err) => {
                this._logService.createErrorMessage(err);
            });
    }

    getProviderStatus(provider) {
        if (
            provider.visibility ===
            this.sdk.AWSDKProviderVisibility.WEB_AVAILABLE
        ) {
            return 'Available Now';
        } else if (
            provider.visibility === this.sdk.AWSDKProviderVisibility.WEB_BUSY
        ) {
            if (provider.waitingRoomCount > 0) {
                return provider.waitingRoomCount + ' waiting';
            }
        } else {
            return 'Offline';
        }
    }

    getOcgProviderTypes(providerTypes) {
        this.providerTypes = [];

        const searchList = this.sdk.__providerTypes.map(
            (item) => item.resourceKey
        );
        this.providerTypes = providerTypes
            .map((item) => item.provider_type_enum)
            .filter((item) => searchList.includes(item));
    }
}
