import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { interval, Subscription, timer } from 'rxjs';
import { IpcService } from '../ipc';
import { LoggerService } from '../logger';
import { SessionService } from '../session';
import { StorageService } from '../storage';
import { UpdateTypeEnum } from '@/shared/enums';

@Injectable({
    providedIn: 'root',
})
export class UpdaterHandlerService {
    private _osRetry = 0;
    private _appRetry = 0;
    private _checkForAppUpdateSub: Subscription;
    private _checkForOsUpdateSub: Subscription;
    constructor(
        private _httpClient: HttpClient,
        private _sessionService: SessionService,
        private _ipcService: IpcService,
        private _loggerService: LoggerService,
        private _storageService: StorageService
    ) {}

    init() {
        this._storageService.subscribeOnUpdateTypeChange((updateType) => {
            console.log('...welcome to onConfigUpdate', updateType);
            if (
                updateType === UpdateTypeEnum.simple ||
                updateType === UpdateTypeEnum.advancedAuto
            ) {
                (!!this._checkForAppUpdateSub || !!this._checkForOsUpdateSub) &&
                    this.stopUpdateInterval();
                this.startUpdateInterval();
            } else if (updateType === 'advanced_manual') {
                this.stopUpdateInterval();
            }
        });
        this.setAppVersion();
        this.setOsVersion();
    }

    startUpdateInterval() {
        this._loggerService.info(
            'update.startUpdateInterval',
            'Starting Update Interval'
        );
        this._checkForAppUpdateSub = interval(30 * 60 * 1000).subscribe(() => {
            this.checkForAppUpdate();
        });
        this._checkForOsUpdateSub = interval(30 * 60 * 1000).subscribe(() => {
            this.checkForOsUpdate();
        });
    }

    stopUpdateInterval() {
        this._loggerService.info(
            'update.stopUpdateInterval',
            'Stopping Update Interval'
        );
        this._checkForAppUpdateSub?.unsubscribe();
        this._checkForOsUpdateSub?.unsubscribe();
        this._checkForAppUpdateSub = null;
        this._checkForOsUpdateSub = null;
    }

    checkForAppUpdate() {
        this._loggerService.info(
            'update.checkForAppUpdate',
            'Checking for App update'
        );

        this._httpClient
            .post(
                `${this._sessionService.fleetUrl}v1/apgar/checkForAppUpdate`,
                {},
                {
                    responseType: 'json',
                    headers: {
                        Authorization: this._sessionService.accessToken,
                    },
                }
            )
            .subscribe(
                (res: any) => {
                    const {
                        appUpdateAvailable,
                        appDownloadUrl,
                        appHashDownloadUrl,
                    } = res;
                    if (appUpdateAvailable) {
                        this._ipcService.requestUpdateApp({
                            url: appDownloadUrl,
                            hashUrl: appHashDownloadUrl,
                        });
                    } else {
                        this._loggerService.info(
                            'update.checkForAppUpdate',
                            'App already up to date'
                        );
                    }
                },
                (err) => {
                    this._loggerService.error('update.checkForAppUpdate', err);
                }
            );
    }

    checkForOsUpdate() {
        this._loggerService.info(
            'update.checkForOsUpdate',
            'Checking for OS update'
        );

        this._httpClient
            .post(
                `${this._sessionService.fleetUrl}v1/apgar/checkForOsUpdate`,
                {},
                {
                    responseType: 'json',
                    headers: {
                        Authorization: this._sessionService.accessToken,
                    },
                }
            )
            .subscribe(
                (res: any) => {
                    const {
                        osUpdateAvailable,
                        osDownloadUrl,
                        osHashDownloadUrl,
                    } = res;
                    if (osUpdateAvailable) {
                        this._ipcService.requestUpdateOs({
                            url: osDownloadUrl,
                            hashUrl: osHashDownloadUrl,
                        });
                    } else {
                        this._loggerService.info(
                            'update.checkForOsUpdate',
                            'OS already up to date'
                        );
                    }
                },
                (err) => {
                    this._loggerService.error('update.checkForOsUpdate', err);
                }
            );
    }

    setAppVersion() {
        this._httpClient
            .post(
                `${this._sessionService.fleetUrl}v1/apgar/setAppVersion`,
                {
                    device_id: this._sessionService.deviceId,
                    app_version: this._sessionService.deviceInfo.appVersion,
                },
                {
                    responseType: 'json',
                    headers: {
                        Authorization: this._sessionService.accessToken,
                    },
                }
            )
            .subscribe(
                (res: any) => {
                    this._loggerService.info('update.setAppVersion', res);
                },
                (err) => {
                    this._loggerService.error('update.setAppVersion', err);
                    timer(5000).subscribe(() => {
                        this._appRetry++;
                        if (this._appRetry < 3) {
                            this.setAppVersion();
                        }
                    });
                }
            );
    }

    setOsVersion() {
        this._httpClient
            .post(
                `${this._sessionService.fleetUrl}v1/apgar/setOsVersion`,
                {
                    device_id: this._sessionService.deviceId,
                    os_version: this._sessionService.deviceInfo.osVersion,
                },
                {
                    responseType: 'json',
                    headers: {
                        Authorization: this._sessionService.accessToken,
                    },
                }
            )
            .subscribe(
                (res: any) => {
                    this._loggerService.info('update.setOsVersion', res);
                },
                (err) => {
                    this._loggerService.error('update.setOsVersion', err);
                    timer(5000).subscribe(() => {
                        this._osRetry++;
                        if (this._osRetry < 3) {
                            this.setOsVersion();
                        }
                    });
                }
            );
    }

    restartDevice() {
        timer(10000).subscribe(() => {
            this._ipcService.restartDevice();
        });
    }
}
