<div class="col s8" [class.wired-ip-width]="joinStaticIPStatus">
    <div class="flex">
        <div class="wired-settings-default">
            <div class="flex justify-between items-center m-b2">
                <h3 class="m-b0">Wired</h3>
                <a automation="joinStaticIp" id="joinStaticIp" class="txt-decoration-none" (click)="joinViaStaticIP()">
                    Join via Static IP
                </a>
            </div>
            <div class="gray-light a30 px15">
                <div class="row m-b0">
                    <div class="col s6 m4 wired-subtitle l3">IPv4 Address</div>
                    <div class="col s6 m8 l9 gray-mid-text" automation="ipv4Address">
                        {{ ethernetStatuses?.ipv4Address }}
                    </div>
                </div>
            </div>
            <div class="gray-light a30 border-top px15">
                <div class="row m-b0">
                    <div class="col s6 m4 wired-subtitle l3">IPv6 Address</div>
                    <div class="col s6 m8 l9 gray-mid-text" automation="ipv6Address">
                        {{ ethernetStatuses?.ipv6Address }}
                    </div>
                </div>
            </div>
            <div class="gray-light a30 border-top px15">
                <div class="row m-b0">
                    <div class="col s6 m4 wired-subtitle l3">Hardware Address</div>
                    <div class="col s6 m8 l9 gray-mid-text" automation="hwAddr">
                        {{ ethernetStatus?.general?.hw_addr }}
                    </div>
                </div>
            </div>
            <div class="gray-light a30 border-top px15">
                <div class="row m-b0">
                    <div class="col s6 m4 wired-subtitle l3">Gateway</div>
                    <div class="col s6 m8 l9 gray-mid-text" automation="gateway">
                        {{ ethernetStatus?.ipv4?.gateway }}
                    </div>
                </div>
            </div>
            <div class="gray-light a30 border-top px15">
                <div class="row m-b0">
                    <div class="col s6 m4 wired-subtitle l3">DNS</div>
                    <div class="col s6 m8 l9 gray-mid-text" automation="ipv4Dns">
                        {{ ethernetStatuses?.ipv4Dns }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="col s4" id="joinStaticIPSection" *ngIf="joinStaticIPStatus">
    <div class="border-left">
        <h3
            automation="leaveJoinStaticIp"
            id="leaveJoinStaticIp"
            class="flex items-center m-b1"
            (click)="leaveJoinViaStaticIP()"
        >
            <i class="material-icons fz-h2 m-t1 m-r2 pointer">chevron_left</i>
            IP Configuration
        </h3>
        <div class="scrollable-wired-settings">
            <div [formGroup]="wiredConfigForm" class="ip-config form relative">
                <div class="row wired-block">
                    <div class="col s12 m12">
                        <aw-radio-button
                            *ngFor="let cm of connectionMethods"
                            (change)="onChangeConnectionMethod()"
                            formControlName="connectionMethod"
                            name="cm"
                            [radioValue]="cm.value"
                        >
                            {{ cm.name }}
                        </aw-radio-button>
                    </div>
                </div>
                <div class="row">
                    <div class="col s12 m12">
                        <div
                            id="joinStaticSelectedEthernet"
                            class="top-indent"
                            *ngIf="wiredConfigForm.value.connectionMethod === addressProtocolTypeStatic"
                        >
                            <app-static-ip-config
                                [defaultStaticIpSetting]="defaultStaticIpSetting"
                                (onChangeDetected)="onStaticIpSettingChange($event)"
                            >
                            </app-static-ip-config>
                        </div>
                        <app-dns-config
                            [addDns]="addDNS"
                            [dnsSettingsForm]="wiredConfigForm"
                            (isDnsChanged)="isDnsChanged($event)"
                            class="top-indent"
                        >
                        </app-dns-config>
                    </div>
                </div>
            </div>
        </div>
        <p class="m0" *ngIf="joinEthernetStatus" automation="joinEthernetStatus">
            <i class="material-icons m-r2 success-text">check_circle</i>{{ joinEthernetStatus }}
        </p>
        <div class="flex justify-end white m-t2">
            <aw-button
                automationValue="wiredFormGoBack"
                *ngIf="joinStaticIPStatus"
                styleClasses="btn-flat btn-primary"
                (onClick)="leaveJoinViaStaticIP()"
            >
                Go back
            </aw-button>
            <aw-button
                automationValue="wiredFormJoin"
                elementId="wiredFormJoinButton"
                styleClasses="btn btn-primary m-l2"
                [disabled]="!isValidToJoin"
                (onClick)="joinEthernetNetwork()"
            >
                Join
            </aw-button>
        </div>
    </div>
</div>
