import { FILE_PATH_OS, LEAVE_CASE_OPEN_QUEUE } from '@/shared/constants';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfirmModalComponent } from '@core/components/confirm-modal/confirm-modal.component';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { IActiveModal } from './../../storage/layout/layout.reducer';
import { LoggerService } from '@services/logger/logger.service';
import { IpcService } from '../ipc/ipc.service';
import { ConfirmModalType } from '@/shared/interfaces/modals/confirm-modal-type.enum';

@Injectable({
    providedIn: 'root',
})
export class CaseService {
    private fleetLog = true;
    private localLog = true;

    constructor(
        private _http: HttpClient,
        private _loggerService: LoggerService,
        private _ipcService: IpcService
    ) {}

    private log(
        module: string,
        detailedInfo: any = {},
        localLog = false
    ): void {
        // log to fleet if activated
        if (this.fleetLog) {
            this._loggerService.log(module, detailedInfo);
        }

        // log to local if activated
        if (this.localLog || localLog) {
            const details = `${module} - ${JSON.stringify({
                ...detailedInfo,
                pageURL: window.location.href,
            })}`;
            this._ipcService.requestWriteFile(
                FILE_PATH_OS.APP_LOG,
                `${details}\n`,
                true
            );
        }
    }

    createCase(rawIntakeData): Observable<any> {
        this.log('[CaseService]: CREATE_CASE', {
            details: { workflow_id: rawIntakeData.workflow_id },
        });
        return this._http
            .post('v10/case', this.rawToV10IntakeData(rawIntakeData))
            .pipe(
                tap((response) => {
                    this.log(`[CaseService]: CREATE_CASE_RESPONSE`, {
                        details: { response },
                    });
                })
            );
    }

    rawToV10IntakeData(rawIntakeData) {
        const v10IntakeData = {
            patientLastName: null,
            patientFirstName: null,
            referringNotes: null,
            sex: null,
            mrn: null,
            callback: null,
        };

        Object.keys(rawIntakeData).forEach((k) => {
            const value = rawIntakeData[k];
            switch (k) {
                case 'lastName':
                    v10IntakeData.patientLastName = value;
                    break;
                case 'firstName':
                    v10IntakeData.patientFirstName = value;
                    break;
                case 'notes':
                    v10IntakeData.referringNotes = value;
                    break;
                case 'gender':
                    v10IntakeData.sex = value;
                    break;
                case 'mrnId':
                    v10IntakeData.mrn = value;
                    break;
                default:
                    v10IntakeData[k] = value;
                    break;
            }
        });

        // The ocg form calls the callback phone field 'phone', so copy that into 'callback' for saving to sites.
        if (rawIntakeData.phone && !v10IntakeData.callback) {
            v10IntakeData.callback = rawIntakeData.phone;
        }

        return v10IntakeData;
    }

    assignCaseWithProvider(
        caseId: any,
        providerId: any,
        workflowId: any
    ): Observable<any> {
        const details = {
            case_id: caseId,
            assigned_to: providerId,
            workflow_id: workflowId,
        };
        this.log('[CaseService]: ASSIGN_CASE_PROVIDER', {
            details: { workflow_id: details.workflow_id },
        });
        return this._http.post('v10/assign_case', details).pipe(
            tap((response) => {
                this.log('[CaseService]: ASSIGN_CASE_PROVIDER_RESPONSE', {
                    details: response,
                });
            })
        );
    }

    cancelCase(
        caseId: string | number,
        workflowId: string | number
    ): Observable<any> {
        const details = {
            case_id: caseId,
            workflow_id: workflowId,
        };
        this.log(`[CaseService]: CANCEL_CASE`, {
            details: { workflow_id: details.workflow_id },
        });
        return this._http.post('v10/cancel_case', details).pipe(
            tap((response) => {
                this.log(`[CaseService]: CANCEL_CASE_RESPONSE`, {
                    details: { response },
                });
            })
        );
    }

    getConfirmCancelCaseModal() {
        return {
            componentName: ConfirmModalComponent.dialogName,
            config: {
                data: {
                    title: 'Are you sure you want to cancel?',
                    body: `<p>Cancelling this case will prevent additional provider documentation.</p>`,
                    confirmText: 'Cancel Case',
                    cancelText: 'Back to Waiting Room',
                    primaryBtnStyle: 'danger',
                    showCloseIcon: false,
                    type: ConfirmModalType.WaitingRoom,
                },
                panelClass: ['aw-modal', 'modal-sm', 'flash-dialog'],
            },
            setProp(
                key: keyof IActiveModal,
                value: IActiveModal[keyof IActiveModal]
            ) {
                this[key] = value;

                return this;
            },
        };
    }

    getConfirmLeaveCaseOpenModal() {
        return {
            componentName: ConfirmModalComponent.dialogName,
            config: {
                data: {
                    title: 'Are you sure you want to exit?',
                    body: `<p>Exiting will keep the case open in the clinical workflow, close the waiting room, and allow you to create another case.</p>`,
                    confirmText: 'Exit Waiting Room',
                    cancelText: 'Back to Waiting Room',
                    primaryBtnStyle: 'primary',
                    showCloseIcon: false,
                    type: ConfirmModalType.WaitingRoom,
                },
                panelClass: ['aw-modal', 'modal-sm', 'flash-dialog'],
            },
            setProp(
                key: keyof IActiveModal,
                value: IActiveModal[keyof IActiveModal]
            ) {
                this[key] = value;

                return this;
            },
        };
    }

    getLeaveCaseOpenSuccessSnackbar(caseId: string | number) {
        this.log('LEAVE_CASE_OPEN_SUCCESS');
        return {
            queue: LEAVE_CASE_OPEN_QUEUE,
            style: 'success',
            description: `You successfully created Case ${caseId} and the notifications have been routed
                to the appropriate providers.`,
        };
    }

    getCancelCaseInfoSnackbar(caseId: string | number) {
        this.log('CANCEL_CASE_INFO');
        return {
            style: 'primary',
            description: `Case ${caseId} was archived.`,
        };
    }
}
