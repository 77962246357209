<span
    *ngIf="roundedLvl === 100 && !acPowerConnected"
    class="material-icons icon iz-24 success-text"
    data-pw="header_battery_icon"
>
    battery_full
</span>
<span
    *ngIf="!(roundedLvl === 100 && !acPowerConnected)"
    class="icon iz-24"
    [ngClass]="batteryClass"
    data-pw="header_battery_icon"
>
    <span *ngIf="pathClassRequired" class="path1"></span>
    <span *ngIf="pathClassRequired" class="path2"></span>
</span>
