import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
    distinctUntilChanged,
    filter,
    first,
    map,
    switchMap,
    take,
    withLatestFrom,
} from 'rxjs/operators';
import { IEndpointConfiguration } from '@shared/interfaces';
import { EndPointService } from '@shared/services/endpoint';
import * as EndpointConfigActions from './endpoint-config.actions';
import * as fromAppReducer from '../../../app.reducer';
import {
    selectAudioInput,
    selectAudioOutput,
    selectMediaDevicesList,
} from '../selectors';
import { combineLatest } from 'rxjs';
import { SYSTEM_COMMANDS_TYPES } from '@/shared/constants/system-command';
import { SessionService } from '@/shared/services/session';
import { MediaDevicesService } from '@/shared/services/media-devices';
import { IpcService } from '@/shared/services/ipc';

@Injectable()
export class EndpointConfigEffects {
    constructor(
        private _action$: Actions,
        private _appStore: Store<fromAppReducer.AppState>,
        private _endpointService: EndPointService,
        private _sessionService: SessionService,
        private _mediaDeviceService: MediaDevicesService,
        private _ipcService: IpcService
    ) {
        this._ipcService
            .listener(SYSTEM_COMMANDS_TYPES.AUDIO_GET_CONTROLS)
            .pipe(first())
            .subscribe(() => {
                combineLatest([
                    this._appStore.select(selectMediaDevicesList),
                    this._appStore.select(selectAudioInput),
                ])
                    .pipe(
                        distinctUntilChanged(),
                        filter(
                            ([listOfMediaDevice]) =>
                                listOfMediaDevice.length > 0
                        )
                    )
                    .subscribe(([, _selectedAudioInput]) => {
                        const audioInput =
                            _selectedAudioInput ||
                            this._mediaDeviceService.defaultAudioInput();
                        this._sessionService?.deviceInstance?.setAudioDefaultSource(
                            audioInput
                        );
                    });

                combineLatest([
                    this._appStore.select(selectMediaDevicesList),
                    this._appStore.select(selectAudioOutput),
                ])
                    .pipe(
                        distinctUntilChanged(),
                        filter(
                            ([listOfMediaDevice]) =>
                                listOfMediaDevice.length > 0
                        )
                    )
                    .subscribe(([, _selectedAudioOutput]) => {
                        const audioOutput =
                            _selectedAudioOutput ||
                            this._mediaDeviceService.defaultAudioOutput();
                        this._sessionService?.deviceInstance?.setAudioDefaultSink(
                            audioOutput
                        );
                    });
            });
    }

    getEndpointConfig = createEffect(
        () =>
            this._action$.pipe(
                ofType(EndpointConfigActions.getEndpointConfig),
                switchMap(() => {
                    const endpointConfigData =
                        this._endpointService.getConfiguration();
                    return endpointConfigData;
                }),
                map((endpointConfigData: IEndpointConfiguration) => {
                    return this._appStore.dispatch(
                        EndpointConfigActions.reloadEndpointConfig({
                            endpointConfig: endpointConfigData,
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    saveEndpointConfig = createEffect(
        () =>
            this._action$.pipe(
                ofType(EndpointConfigActions.updateEndpointConfig),
                withLatestFrom(this._appStore.select('endpointConfig')),
                map(([endpointConfigData, endpointConfigState]) => {
                    const currentEndpointConfig = { ...endpointConfigState };
                    currentEndpointConfig[endpointConfigData.key] =
                        endpointConfigData.value;
                    if (!endpointConfigData.skipSettings) {
                        this._endpointService
                            .saveConfiguration(currentEndpointConfig)
                            .pipe(take(1))
                            .subscribe(
                                () => {
                                    // fire and forget settings
                                },
                                (err) => console.log('HTTP Error', err)
                            );
                    } else {
                        ['AudioInput', 'AudioOutput', 'VideoInput'].includes(
                            endpointConfigData.key
                        ) &&
                            (currentEndpointConfig[
                                'Default' + endpointConfigData.key
                            ] = false);
                    }

                    return EndpointConfigActions.reloadEndpointConfig({
                        endpointConfig: currentEndpointConfig,
                    });
                })
            )
        // { dispatch: false }
    );
}
