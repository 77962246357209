<div class="gradient-diagonal-blue h100 overflow-x-hidden overflow-auto">
    <div class="header-content-light flex pt3 justify-between items-center">
        <div class="flex items-center m-r3">
            <img class="header-logo" src="../../../../assets/images/AmwellWhiteLogo.svg" />
        </div>
    </div>
    <div
        class="page-content-light flex justify-center items-center flex-column pb35"
        [@fadeAnimation]="outlet.isActivated ? outlet.activatedRoute : ''"
    >
        <router-outlet #outlet="outlet"></router-outlet>
    </div>
</div>
