import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class EnvironmentConfigService {
    private _settings;

    constructor(private _http: HttpClient) {}

    public init() {
        return this._http?.get('/assets/config/app-config.json').pipe(
            // TODO - Add typings.
            tap((data: { amwell: any; avizia: any }) => {
                this._settings = location.hostname.endsWith('avizia.com')
                    ? data?.avizia ?? null
                    : data?.amwell ?? null;
            })
        );
    }

    getSettings() {
        return this._settings || {};
    }
}
