import { DeviceService } from '@/shared/services/device';
import { TrackEventDataKeys } from '@/shared/services/statistic-collector/static-collector-defined-data';
import { StatisticCollector } from '@/shared/services/statistic-collector/statistic-collector.service';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-turn-off-dialog',
    template: `
        <div class="sized-modal">
            <div class="modal-heading no-border">
                <h3 class="sized-title m-b0">Turn Off</h3>
            </div>
            <div class="modal-content">
                <p class="sized-txt">
                    Are you sure you would like to Turn Off the device?
                </p>
            </div>
            <div class="modal-footer flex justify-end pb2">
                <aw-button
                    styleClasses="btn m-r2 btn-simple btn-large waves-effect waves-light"
                    (onClick)="closeModal()"
                    >Cancel</aw-button
                >
                <aw-button
                    styleClasses="waves-effect waves-light btn btn-danger modal-trigger btn-large"
                    (onClick)="turnOff()"
                >
                    Turn Off
                </aw-button>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TurnOffDialogComponent {
    constructor(
        private _matDialogRef: MatDialogRef<TurnOffDialogComponent>,
        private _statisticCollector: StatisticCollector,
        private _deviceService: DeviceService
    ) {}

    public closeModal(): void {
        this._statisticCollector.trackEvent(
            TrackEventDataKeys.CancelTurnOffDevice
        );
        this._matDialogRef.close();
    }

    public turnOff(): void {
        this._statisticCollector.trackEvent(
            TrackEventDataKeys.InitTurnOffDevice
        );
        this._deviceService.turnOff();
        this._matDialogRef.close();
    }
}
