import { NgModule } from '@angular/core';
import { SharedModule } from '@/shared/shared.module';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { CoreRoutingModule } from './core.routing';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
    ApiUrlInterceptor,
    ErrorInterceptor,
    JwtInterceptor,
    InternetInterceptor,
} from './interceptors';

import {
    FullLayoutComponent,
    EmptyLayoutComponent,
    LightLayoutComponent,
    TVKitLayoutComponent,
    EmptyDashboardLayout,
} from './layouts';

import { SettingsModalComponent } from '@/settings/settings-modal/settings-modal.component';
import { SettingsModule } from '@/settings/settings.module';
import { SettingsPinModalComponent } from '@/settings/settings-pin-modal/settings-pin-modal.component';
import { LostNetworkModalComponent } from './components/lost-network-modal/lost-network-modal.component';
import { CommonModule } from '@angular/common';
import { ErrorGenericModule } from '@/error-generic';
import { NetworkIndicatorComponent } from './components/network-indicator/network-indicator.component';
import { EndpointPhotoComponent } from './components/endpoint-photo/endpoint-photo.component';
import { LowBatteryModalComponent } from './components/low-battery-modal/low-battery-modal.component';
import { MenuComponent } from './components/menu/menu.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { WakeUpScreenComponent } from './components/wake-up-screen/wake-up-screen.component';
import { RollbackDialogComponent } from './components/dialogs/rollback-dialog/rollback-dialog.component';
import { RollbackErrorDialogComponent } from './components/dialogs/rollback-error-dialog/rollback-error-dialog.component';
import { RollbackProgressDialogComponent } from './components/dialogs/rollback-progress-dialog/rollback-progress-dialog.component';
import { TurnOffDialogComponent } from './components/dialogs/turn-off-dialog.component';
import { FactoryDefaultDialogComponent } from './components/dialogs/factory-default-dialog.component';
import { RestartDialogComponent } from './components/dialogs/restart-dialog.component';

@NgModule({
    declarations: [
        FullLayoutComponent,
        EmptyLayoutComponent,
        LightLayoutComponent,
        TVKitLayoutComponent,
        EmptyDashboardLayout,
        NetworkIndicatorComponent,
        EndpointPhotoComponent,
        LowBatteryModalComponent,
        MenuComponent,
        ConfirmModalComponent,
        WakeUpScreenComponent,
        SettingsModalComponent,
        SettingsPinModalComponent,
        LostNetworkModalComponent,
        RollbackDialogComponent,
        RollbackErrorDialogComponent,
        RollbackProgressDialogComponent,
        // Dialogs
        TurnOffDialogComponent,
        FactoryDefaultDialogComponent,
        RestartDialogComponent,
    ],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule,
        SharedModule,
        CommonModule,
        RouterModule,
        CoreRoutingModule,
        SettingsModule,
        ErrorGenericModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiUrlInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InternetInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true,
        },
    ],
    exports: [SharedModule, MatDialogModule, WakeUpScreenComponent],
})
export class CoreModule {}
