import { AppState } from '@/app.reducer';
import { createSelector } from '@ngrx/store';
import { IAppUpdate, ILayoutState, IModalState, IToastState } from '../layout';

export const selectLayoutState = (state: AppState): ILayoutState =>
    state.layout;
export const selectModalState = createSelector(
    selectLayoutState,
    (state: ILayoutState) => state.modal
);

export const selectActiveModalStack = createSelector(
    selectModalState,
    (state: IModalState) => state.activeModalStack
);
export const selectActiveModal = createSelector(
    selectModalState,
    (state: IModalState) => state.activeModalStack[0]
);
export const selectIsOpenSettingModal = createSelector(
    selectModalState,
    (state: IModalState) => state.isOpenSettingModal
);
export const selectActiveTabSettingsModal = createSelector(
    selectModalState,
    (state: IModalState) => state.activeTabSettingsModal
);
export const selectToastState = createSelector(
    selectLayoutState,
    (state: ILayoutState) => state.toast
);
export const selectActiveToast = createSelector(
    selectToastState,
    (state: IToastState) => state.activeToastStack[0]
);

export const selectloadingServiceLines = createSelector(
    selectLayoutState,
    (state: ILayoutState) => state.loadingServiceLines
);

// Application Updates

export const selectAppUpdate = createSelector(
    selectLayoutState,
    (state: ILayoutState) => state.appUpdate
);
export const selectIsAppUpdateAvailable = createSelector(
    selectAppUpdate,
    (state: IAppUpdate) => state.isAppUpdateAvailable
);
export const selectIntervalToCheckAppUpdate = createSelector(
    selectAppUpdate,
    (state: IAppUpdate) => state.intervalToCheckAppUpdate
);
