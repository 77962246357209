import {
    Injectable,
    OnDestroy,
    Renderer2,
    RendererFactory2,
} from '@angular/core';

import { WebsocketService } from '@/websocket/websocket.service';
import {
    APPLICATION_MANAGER,
    AUDIO_MANAGER,
    CAMERA_MANAGER,
    CRADLE_COMMS,
    EMBRAVIA_LIGHTS,
    ENCRYPTION_TYPES,
    FILE,
    NETWORK,
    POWER_MANAGER,
    SPINE,
    SYSTEM_INFO_MANAGER,
    TV_CONTROL,
    UPDATER,
    USB_MANAGER,
    commandAdditionalInfo,
} from '@shared/constants';
import { IpcObserverService } from '@shared/services/ipc/ipc-observer.service';
import { StorageService } from '@shared/services/storage/storage.service';
import { Observable, Subscription, asyncScheduler, timer } from 'rxjs';

import { FILE_PATH_OS } from '@/shared/constants';
import { IAudioMuteControlData } from '@/shared/interfaces/commands/audio-mute-control-data.interface';
import { IAudioVolumeControlSourceData } from '@/shared/interfaces/commands/audio-volume-control-source.interface';
import { Queue } from '@/shared/queue/queue';
import { IAudioVolumeControlSinkData } from '@interfaces/commands/audio-volume-control-sink.interface';
import { LoggerService } from '@services/logger/logger.service';
import { BatteryStates } from '@shared/enums';
import find from 'lodash/find';
import {
    bufferTime,
    filter,
    map,
    observeOn,
    takeWhile,
    withLatestFrom,
} from 'rxjs/operators';

import {
    IPC_REQUEST,
    SUB_SYSTEM_TYPES,
    SYSTEM_COMMANDS_TYPES,
} from '@/shared/constants/system-command';
import { setPtzFeccPositionPrivacy } from '@/shared/storage/ptz/ptz.actions';
import {
    selectIsFleetConnected,
    selectIsInternetConnected,
} from '@/shared/storage/selectors/auth.selector';
import { Store } from '@ngrx/store';

@Injectable({ providedIn: 'root' })
export class IpcService implements OnDestroy {
    private readonly _LOCAL_LOGS_FLUSH_INTERVAL = 60000;
    private _messageQueue = new Queue();
    private _alive = true;
    private _disableClick = 'disable-click';
    private _batteryType: 'RIPPLE' | 'PANDORA';
    private renderer: Renderer2;
    public removeEventListener: () => void;
    constructor(
        private _webSocketService: WebsocketService,
        private _ipcObserverService: IpcObserverService,
        private _loggerService: LoggerService,
        private _store: Store,
        private rendererFactory: RendererFactory2,
        private _storageService: StorageService
    ) {
        this.renderer = this.rendererFactory.createRenderer(null, null);
        this.processQueue();
        this.addListeners();
    }

    ngOnDestroy(): void {
        this._alive = false;

        if (this.removeEventListener) {
            this.removeEventListener();
        }
    }

    private processQueue(): void {
        const listToSkipLogsSubsystem = [
            SUB_SYSTEM_TYPES.NETWORK_MANAGER,
            SUB_SYSTEM_TYPES.TV_CONTROL,
            SUB_SYSTEM_TYPES.FILE_MANAGER,
        ];
        const listToSkipLogsCommand = [
            SYSTEM_COMMANDS_TYPES.GET_NETWORK_STATUS,
            SYSTEM_COMMANDS_TYPES.GET_STATUS,
            SYSTEM_COMMANDS_TYPES.WRITE_FILE,
        ];

        const observable$ = this._messageQueue.getMessageQueue().pipe(
            takeWhile(() => this._alive),
            observeOn(asyncScheduler)
        );
        observable$.subscribe((item) => {
            if (item) {
                this._webSocketService.send(item);
                // hide password in logs
                if (item.network && item.network.password) {
                    item.network.password = '********';
                }
                // do not log request for NETWORK_STATUS, TV_STATUS, FILE_WRITE
                const ipcCommand = Object.values(item)[0] as any;
                const isSkipToLogSub = listToSkipLogsSubsystem.includes(
                    ipcCommand?.subsystem
                );
                const isSkipToCommand = listToSkipLogsCommand.includes(
                    ipcCommand?.command
                );
                const osLogFilePath = ipcCommand.path;
                const isSkipToLog = isSkipToLogSub && isSkipToCommand;

                if (!isSkipToLog && osLogFilePath !== FILE_PATH_OS.APP_LOG) {
                    this._loggerService.info(IPC_REQUEST, JSON.stringify(item));
                }
            }
        });

        // processing logger queue for OS logs
        this._loggerService.loggerQueue$
            .pipe(
                takeWhile(() => this._alive),
                filter((log) => {
                    const isValid =
                        log?.detailedInfo?.file?.subsystem !==
                            SUB_SYSTEM_TYPES.FILE_MANAGER &&
                        log?.detailedInfo?.file?.command !==
                            SYSTEM_COMMANDS_TYPES.WRITE_FILE &&
                        log?.detailedInfo?.file?.path !== FILE_PATH_OS.APP_LOG;

                    return isValid;
                }),
                withLatestFrom(
                    this._store.select(selectIsInternetConnected),
                    this._store.select(selectIsFleetConnected)
                ),
                map(([log, isInternetConnected, isFleetConnected]) => ({
                    ...log,
                    isInternetConnected,
                    isFleetConnected,
                    pageURL: window.location.href,
                })),
                map((log) => JSON.stringify(log)),
                bufferTime(this._LOCAL_LOGS_FLUSH_INTERVAL),
                filter((logs) => logs.length > 0),
                map((logs) => logs.join('\n'))
            )
            .subscribe((concatLogs) => {
                this.requestWriteFile(FILE_PATH_OS.APP_LOG, concatLogs, true);
            });
    }

    listener(command: string): Observable<any> {
        return this._ipcObserverService.addSubscription(command);
    }

    notify(command: string, data = null): void {
        this._ipcObserverService.notifyChanges({
            command,
            data,
        });
    }

    /** start response listeners only */

    private addListeners(): void {
        this.listener(SYSTEM_COMMANDS_TYPES.HOTPLUG)
            .pipe(takeWhile(() => this._alive))
            .subscribe(() => {
                this.requestUsbList();
                this.requestAudioControls();
            });

        this.listener(SYSTEM_COMMANDS_TYPES.NERVE_GET_DETECTED)
            .pipe(takeWhile(() => this._alive))
            .subscribe(() => {
                if (this._storageService.getBatteryType() === 'RIPPLE') {
                    this.requestRippleGetAcBattInfo();
                } else if (
                    this._storageService.getBatteryType() === 'PANDORA'
                ) {
                    this.requestPandoraGetAcBattInfo();
                } else {
                    this._loggerService.error('Unknown battery detected');
                }
            });
        this.listener(SYSTEM_COMMANDS_TYPES.SET_HOSTNAME)
            .pipe(takeWhile(() => this._alive))
            .subscribe(() => {
                this.requestGetHostName();
            });
    }

    subscribeOnConnectWifi(callback): Subscription {
        return this.listener(SYSTEM_COMMANDS_TYPES.CONNECT_WIFI).subscribe(
            callback
        );
    }

    subscribeOnConnectEthernet(callback): Subscription {
        return this.listener(SYSTEM_COMMANDS_TYPES.SET_IP_CONFIG).subscribe(
            callback
        );
    }

    subscribeOnHeadsetMicDetect(): Observable<any> {
        return this.listener(SYSTEM_COMMANDS_TYPES.HEADSET_MIC_DETECT);
    }

    subscribeOnLineInDetect(): Observable<any> {
        return this.listener(SYSTEM_COMMANDS_TYPES.LINE_IN_DETECT);
    }

    subscribeOnHeadphoneDetect(): Observable<any> {
        return this.listener(SYSTEM_COMMANDS_TYPES.HEADPHONES_DETECT);
    }

    subscribeOnStandByState(): Observable<any> {
        return this.listener(SYSTEM_COMMANDS_TYPES.STANDBY_STATE);
    }

    /** end response listeners only */

    /** start request only commands */

    makeIpcRequest(payload: any = {}): void {
        this._messageQueue.sendMessageQueue(payload);
    }

    requestAddDomainToDns(request): Observable<any> {
        const command = NETWORK.ADD_DOMAIN_TO_DNS;
        command.network.domain = request.domain;
        this.makeIpcRequest(command);
        return this._ipcObserverService.addSubscription(
            command.network.command
        );
    }

    requestCallEnd(): void {
        const command = {
            subsystem: SUB_SYSTEM_TYPES.APPLICATION,
            command: SYSTEM_COMMANDS_TYPES.CALL_END,
        };
        this.makeIpcRequest(command);
    }

    requestNerveGetDetected(): void {
        const command = SPINE.NERVE_GET_DETECTED;
        this.makeIpcRequest(command);
    }

    requestResolveDomain(info: commandAdditionalInfo): Observable<any> {
        const command = { ...NETWORK.RESOLVE_DOMAIN };
        command.reserved = { ...info, pageURL: window.location.href };
        this.makeIpcRequest(command);
        return this._ipcObserverService.addSubscription(
            command.network.command
        );
    }

    requestGetAcBattInfo(): void {
        if (this._batteryType === 'RIPPLE') {
            this.requestRippleGetAcBattInfo();
        } else if (this._batteryType === 'PANDORA') {
            this.requestPandoraGetAcBattInfo();
        } else {
            this._loggerService.error('Unknown battery detected.');
        }
    }

    requestRippleGetAcBattInfo(): void {
        const command = SPINE.RIPPLE_GET_AC_BATT_INFO;
        this.makeIpcRequest(command);
    }

    requestPandoraGetAcBattInfo(): void {
        const command = SPINE.PANDORA_GET_AC_BATT_INFO;
        this.makeIpcRequest(command);
    }

    requestChangeLedRingColor(state: BatteryStates | string): void {
        let command;
        if (state === 'NETWORK_ERROR') {
            command = SPINE.LED_RING_ANIMATION_NET_ERROR;
        } else if (state === 'REGISTRATION_ERROR') {
            command = SPINE.LED_RING_ANIMATION_REG_ERROR;
        } else if (state === 'ONLINE') {
            command = SPINE.LED_RING_ON;
        } else if (state === 'STANDBY') {
            command = SPINE.LED_RING_ANIMATION_STANDBY;
        } else if (state === 'LOW_BATTERY') {
            command = SPINE.LED_RING_ANIMATION_LOW_BATTERY;
        }
        this.makeIpcRequest(command);
    }

    requestDeleteAllNetworks(): void {
        const command = NETWORK.DELETE_ALL_NETWORKS;
        this.makeIpcRequest(command);
    }

    requestReadFile(path: string): Observable<any> {
        const command = { ...FILE.READ_FILE };
        command.file = { ...command.file };
        command.file.path = path;
        this.makeIpcRequest(command);
        return this._ipcObserverService
            .addSubscription(command.file.command)
            .pipe(filter((files) => files.file.path === command.file.path));
    }

    requestWriteFile(
        path: string,
        content: string,
        isAppend = false
    ): Observable<any> {
        const command = { ...FILE.WRITE_FILE };
        command.file = { ...command.file };
        command.file.path = path;
        command.file.contents = content;
        command.file.append = isAppend;
        this.makeIpcRequest(command);
        return this._ipcObserverService
            .addSubscription(command.file.command)
            .pipe(filter((files) => files.file.path === command.file.path));
    }

    requestDeleteFile(request): void {
        const command = {
            ...FILE.REMOVE_FILE,
            ...{ file: { ...FILE.REMOVE_FILE.file } },
        };
        command.file.path = request.path;
        this.makeIpcRequest(command);
    }

    requestFactoryDefault(): void {
        const command = SYSTEM_INFO_MANAGER.FACTORY_DEFAULT_COMMAND;
        this.makeIpcRequest(command);
        this.requestDeleteAllNetworks();
        this.requestDeleteFile({
            path: FILE_PATH_OS.MINRRAY_CAMERA,
        });

        this.requestDeleteFile({
            path: FILE_PATH_OS.QRCODE,
        });

        this.notify(SYSTEM_COMMANDS_TYPES.FACTORY_DEFAULT);
        timer(10000).subscribe(() => {
            this.requestReboot();
        });
    }

    requestReboot(): void {
        const command = POWER_MANAGER.REBOOT;
        this.makeIpcRequest(command);
    }

    restartDevice(): void {
        this._store.dispatch(setPtzFeccPositionPrivacy());
        const command = POWER_MANAGER.REBOOT;
        this.makeIpcRequest(command);
    }

    restartApp(): void {
        const command = APPLICATION_MANAGER.RESTART;
        this.makeIpcRequest(command);
    }

    restartBrowser(): void {
        const command = { ...APPLICATION_MANAGER.RESTART_BROWSER };
        this.makeIpcRequest(command);
    }

    shutdownDevice(): void {
        const command = POWER_MANAGER.SHUTDOWN;
        this.makeIpcRequest(command);
    }

    requestSystemInfo(): Observable<any> {
        const command = SYSTEM_INFO_MANAGER.SYSTEM_INFO_COMMAND;
        this.makeIpcRequest(command);
        return this._ipcObserverService.addSubscription(
            command.system_info.command
        );
    }

    requestNetworkList(): void {
        const command = NETWORK.GET_NETWORK_LIST;
        this.makeIpcRequest(command);
    }

    requestNetworkStatus(): void {
        const command = NETWORK.GET_NETWORK_STATUS;
        this.makeIpcRequest(command);
    }

    requestLedRingOff(): void {
        const command = SPINE.LED_RING_OFF;
        this.makeIpcRequest(command);
    }

    requestRollback(subSystemType: string): void {
        const { ROLLBACK_APP, ROLLBACK_OS } = UPDATER;
        const command =
            subSystemType === SUB_SYSTEM_TYPES.APPLICATION
                ? ROLLBACK_APP
                : ROLLBACK_OS;
        this.makeIpcRequest(command);
    }

    requestUpdateApp(request): void {
        const command = UPDATER.UPDATE_APP;
        command.updater.url = request.url;
        command.updater.hash_url = request.hashUrl;
        this.makeIpcRequest(command);
    }

    requestUpdateOs(request): void {
        const command = UPDATER.UPDATE_OS;
        command.updater.url = request.url;
        command.updater.hash_url = request.hashUrl;
        this.makeIpcRequest(command);
    }

    setMicGain(data: IAudioVolumeControlSinkData): void {
        const command = AUDIO_MANAGER.AUDIO_VOLUME_CONTROL_SOURCE;
        command.audio.parameters.card_name = data.cardName;
        command.audio.parameters.ctrl_name = data.ctrlName;
        command.audio.parameters.device_type = data.deviceType;
        command.audio.parameters.volume = Math.round(
            parseInt(data.volume)
        ).toString();
        this.makeIpcRequest(command);
    }

    setSpeakerGain(data: IAudioVolumeControlSourceData): void {
        const command = AUDIO_MANAGER.AUDIO_VOLUME_CONTROL_SINK;
        command.audio.parameters.card_name = data.cardName;
        command.audio.parameters.ctrl_name = data.ctrlName;
        command.audio.parameters.device_type = data.deviceType;
        command.audio.parameters.volume = Math.round(
            parseInt(data.volume)
        ).toString();
        this.makeIpcRequest(command);
    }

    getAudioControls() {
        const audioControlsCommand = AUDIO_MANAGER.AUDIO_GET_CONTROLS;
        return this._ipcObserverService
            .addSubscription(audioControlsCommand.audio.command)
            .pipe(filter((res) => Array.isArray(res) && !!res.length));
    }

    setPeripheralGain(peripheralGainData): void {
        const audioVolumeControlCommand =
            AUDIO_MANAGER.AUDIO_VOLUME_CONTROL_SOURCE;
        audioVolumeControlCommand.audio.parameters.device_type =
            peripheralGainData.deviceType;
        audioVolumeControlCommand.audio.parameters.card_name =
            peripheralGainData.cardName;
        audioVolumeControlCommand.audio.parameters.ctrl_name =
            peripheralGainData.ctrlName;
        audioVolumeControlCommand.audio.parameters.volume =
            peripheralGainData.volume;
        this.makeIpcRequest(audioVolumeControlCommand);
    }

    setMute(data: IAudioMuteControlData): void {
        const command = AUDIO_MANAGER.AUDIO_MUTE_CONTROL;
        command.audio.parameters.card_name = data.cardName;
        command.audio.parameters.ctrl_name = data.ctrlName;
        command.audio.parameters.device_type = data.deviceType;
        command.audio.parameters.mute = data.mute;
        this.makeIpcRequest(command);
    }

    requestAudioControls(): void {
        const command = AUDIO_MANAGER.GET_AUDIO_CONTROLS;
        this.makeIpcRequest(command);
    }

    setLEDRingOn(): void {
        const command = SPINE.LED_RING_ON;
        this.makeIpcRequest(command);
    }

    pollAudioJackStatus(): void {
        const command = SPINE.POLL_AUDIO_JACK_STATUS;
        this.makeIpcRequest(command);
    }

    requestFirmwareVersion(): void {
        const command = SPINE.GET_FW_VERSION;
        this.makeIpcRequest(command);
    }

    requestSystemInfoForCart500(): void {
        this.requestFirmwareVersion();
        this.requestGetSerialNumber();
        this.setLEDRingOn();
        this.requestNerveGetDetected();
        this.pollAudioJackStatus();
    }

    requestGetSerialNumber(): void {
        const command = SPINE.GET_SERIAL_NUMBER;
        this.makeIpcRequest(command);
    }

    setLEDWorkSurfaceAuto(): void {
        const command = SPINE.LED_WORKSURFACE_AUTO;
        this.makeIpcRequest(command);
    }

    setLEDWorkSurfaceOn(): void {
        const command = SPINE.LED_WORKSURFACE_ON;
        this.makeIpcRequest(command);
    }

    setLEDWorkSurfaceOff(): void {
        const command = SPINE.LED_WORKSURFACE_OFF;
        this.makeIpcRequest(command);
    }

    requestCradleStats(): void {
        const command = CRADLE_COMMS.GET_CRADLESTATS_COMMAND;
        this.makeIpcRequest(command);
    }

    requestTVStatus(): void {
        const command = TV_CONTROL.GET_TV_STATUS;
        this.makeIpcRequest(command);
    }

    setTVTurnOff(): void {
        const command = TV_CONTROL.TV_TURN_OFF;
        this.makeIpcRequest(command);
    }

    setTVTurnOn(): void {
        const command = TV_CONTROL.TV_TURN_ON;
        this.makeIpcRequest(command);
    }

    requestLEDStatus(): void {
        const command = EMBRAVIA_LIGHTS.GET_LED_STATUS;
        this.makeIpcRequest(command);
    }

    setLEDTurnOff(): void {
        const command = EMBRAVIA_LIGHTS.LED_TURN_OFF;
        this.makeIpcRequest(command);
    }

    setPrimaryTVSource(source: string): void {
        const command = TV_CONTROL.TV_SET_PRIMARY_SOURCE;
        command.peripherals.source = source;

        this.makeIpcRequest(command);
    }

    returnToPreviousSource(): void {
        const command = TV_CONTROL.RETURN_TO_PREVIOUS_SOURCE;
        this.makeIpcRequest(command);
    }

    switchToPrimarySource(): void {
        const command = TV_CONTROL.SWITCH_TO_PRIMARY_SOURCE;
        this.makeIpcRequest(command);
    }

    requestPowerStatus(): void {
        const command = POWER_MANAGER.GET_POWER_STATS;
        this.makeIpcRequest(command);
    }

    requestUsbList(): void {
        const command = USB_MANAGER.GET_LIST;
        this.makeIpcRequest(command);
    }

    requestGetHostName(): void {
        const command = NETWORK.GET_HOST_NAME;
        this.makeIpcRequest(command);
    }

    subscribeOnAudioVideoChanges(): Observable<any> {
        return this.listener(SYSTEM_COMMANDS_TYPES.AUDIO_VIDEO_CHANGE);
    }

    disableClickEvents() {
        if (this.removeEventListener) {
            this.removeEventListener();
        }
        const el = document.body.firstElementChild;
        this.renderer.addClass(el, this._disableClick);
        this.removeEventListener = this.renderer.listen(el, 'click', () => {
            this.enableClickEvents();
        });
    }

    enableClickEvents() {
        const el = document.body.firstElementChild;
        if (el.className === this._disableClick) {
            timer(1000).subscribe(() => {
                this.renderer.removeClass(el, this._disableClick);
            });
        }
    }

    setDisplayTurnOn(): void {
        this.enableClickEvents();
        const command = POWER_MANAGER.DISPLAY_TURN_ON;
        this.makeIpcRequest(command);
    }

    setDisplayTurnOff(): void {
        const command = POWER_MANAGER.DISPLAY_TURN_OFF;
        this.makeIpcRequest(command);
    }

    setLEDTurnOn(color: string): void {
        const command = EMBRAVIA_LIGHTS.LED_TURN_ON;
        command.peripherals.color = color;
        this.makeIpcRequest(command);
    }

    enableEchoCancel(audioInput: any, audioOutput: any): void {
        const command = AUDIO_MANAGER.AUDIO_ENABLE_ECHO_CANCEL;
        command.audio.parameters.source = audioInput
            ? audioInput.ctrl_name
            : '';
        command.audio.parameters.sink = audioOutput
            ? audioOutput.ctrl_name
            : '';
        this.makeIpcRequest(command);
    }

    setToProdMode(): void {
        const command = SYSTEM_INFO_MANAGER.PROD_MODE;
        this.makeIpcRequest(command);
    }

    setToDevMode(): void {
        const command = SYSTEM_INFO_MANAGER.DEV_MODE;
        this.makeIpcRequest(command);
    }

    setAudioDefaultSource(source: string): void {
        const command = AUDIO_MANAGER.AUDIO_SET_DEFAULT_SOURCE;
        command.audio.source = source;
        this.makeIpcRequest(command);
    }

    setAudioDefaultSink(sink: string): void {
        const command = AUDIO_MANAGER.AUDIO_SET_DEFAULT_SINK;
        command.audio.sink = sink;
        this.makeIpcRequest(command);
    }

    setAudioControlMaxVolume(maxVolume: number, deviceType: string): void {
        const command = AUDIO_MANAGER.AUDIO_CHANGE_CONTROL_MAX_VOLUME;
        command.audio.parameters.max_volume = `${maxVolume}`;
        command.audio.parameters.device_type = deviceType;
        this.makeIpcRequest(command);
    }

    requestStopWatchDog() {
        const command = APPLICATION_MANAGER.STOP_WATCHDOG;
        this.makeIpcRequest(command);
    }

    /** end request only commands */

    /** start request/response commands */

    reenumerateDevice(input): Observable<any> {
        const command = USB_MANAGER.REENUMERATE_DEVICE;
        command.usb.product = input.product;
        command.usb.pid = input.pid;
        command.usb.vid = input.vid;
        this.makeIpcRequest(command);
        return this._ipcObserverService.addSubscription(
            SYSTEM_COMMANDS_TYPES.REENUMERATE_DEVICE
        );
    }

    requestConnectWiFi(input): Observable<any> {
        const command = NETWORK.CONNECT_NETWORK;

        if (input.hidden) {
            // connecting to hidden network
            const encryption = find(ENCRYPTION_TYPES, {
                name: input.encryption || null,
            });
            if (encryption) {
                input.key_mgmt = encryption.key_mgmt;
                input.eap = encryption.eap;
                input.phase_2_auth = encryption.phase_2_auth;
            }
        }

        command.network.username = input.username || '';
        command.network.password = input.password || '';
        command.network.ssid = input.ssid || '';
        command.network.key_mgmt = input.key_mgmt || '';
        command.network.eap = input.eap || '';
        command.network.phase_2_auth = input.phase_2_auth || '';
        command.network.hidden = input.hidden ? 'yes' : 'no';
        command.network.locked_band = input.locked_band || 'BAND_AUTO';
        command.network.address_protocol = input.address_protocol || 'DHCP';
        command.network.ipv4 =
            input.ipv4 || NETWORK.DEFAULT_CONNECT_NETWORK_COMMAND_IPV4;
        command.network.ipv6 =
            input.ipv6 || NETWORK.DEFAULT_CONNECT_NETWORK_COMMAND_IPV6;

        this.makeIpcRequest(command);
        return this._ipcObserverService.addSubscription(
            SYSTEM_COMMANDS_TYPES.CONNECT_WIFI
        );
    }

    requestSetIpConfig(input): Observable<any> {
        const command = NETWORK.SET_IP_CONFIG;

        command.network.ssid = input.ssid || '';
        command.network.address_protocol = input.address_protocol || 'DHCP';
        command.network.ipv4 =
            input.ipv4 || NETWORK.DEFAULT_CONNECT_NETWORK_COMMAND_IPV4;
        command.network.ipv6 =
            input.ipv6 || NETWORK.DEFAULT_CONNECT_NETWORK_COMMAND_IPV6;
        command.network.connection_type = input.connection_type;
        this.makeIpcRequest(command);
        return this._ipcObserverService.addSubscription(
            SYSTEM_COMMANDS_TYPES.SET_IP_CONFIG
        );
    }

    requestSetHostName(hostName: string): Observable<any> {
        const command = NETWORK.SET_HOST_NAME;
        command.network.hostname = hostName;
        this.makeIpcRequest(command);
        return this._ipcObserverService.addSubscription(
            SYSTEM_COMMANDS_TYPES.SET_HOSTNAME
        );
    }

    isOnline(info: commandAdditionalInfo): Observable<any> {
        const command = { ...NETWORK.RESOLVE_DOMAIN };
        // reserved takes additional info like component, pageURL and returns the same info in the response
        command.reserved = { ...info, pageURL: window.location.href };
        this.makeIpcRequest(command);

        return this._ipcObserverService.addSubscription(
            command.network.command
        );
    }

    requestGetNetworkConfig(networkSSID): Observable<any> {
        const command = NETWORK.GET_NETWORK_CONFIG;
        command.network.ssid = networkSSID;
        this.makeIpcRequest(command);
        return this._ipcObserverService.addSubscription(
            command.network.command
        );
    }

    requestGetLockedBand(networkSSID): Observable<any> {
        const command = NETWORK.GET_LOCKED_BAND;
        command.network.ssid = networkSSID;
        this.makeIpcRequest(command);
        return this._ipcObserverService.addSubscription(
            command.network.command
        );
    }

    requestGetDNSServers(): Observable<any> {
        const command = NETWORK.GET_DNS_SERVERS;
        this.makeIpcRequest(command);
        return this._ipcObserverService.addSubscription(
            command.network.command
        );
    }

    requestAddServerArrayToDNS(data): Observable<any> {
        const command = NETWORK.ADD_SERVER_ARRAY_TO_DNS;
        command.network.server = data;
        this.makeIpcRequest(command);
        return this._ipcObserverService.addSubscription(
            command.network.command
        );
    }

    requestDeleteNetwork(ssid: any): Observable<any> {
        const command = NETWORK.DELETE_NETWORK;
        command.network.ssid = ssid;
        this.makeIpcRequest(command);
        return this._ipcObserverService.addSubscription(
            command.network.command
        );
    }

    setRoamingThreshold(threshold: string): Observable<any> {
        const command = NETWORK.SET_ROAMING_THRESHOLD;
        command.network.threshold = threshold.toUpperCase();
        this.makeIpcRequest(command);
        return this._ipcObserverService.addSubscription(
            command.network.command
        );
    }

    requestTVTurnOn(): Observable<any> {
        const command = TV_CONTROL.TV_TURN_ON;
        this.makeIpcRequest(command);
        return this._ipcObserverService.addSubscription(
            command.peripherals.command
        );
    }

    requestSwitchToPrimarySource(): Observable<any> {
        const command = TV_CONTROL.SWITCH_TO_PRIMARY_SOURCE;
        this.makeIpcRequest(command);
        return this._ipcObserverService.addSubscription(
            command.peripherals.command
        );
    }

    getRoamingThreshold(): Observable<any> {
        const command = NETWORK.GET_ROAMING_THRESHOLD;
        this.makeIpcRequest(command);
        return this._ipcObserverService.addSubscription(
            command.network.command
        );
    }

    requestSetLockedBand(data: any): Observable<any> {
        const command = NETWORK.SET_LOCKED_BAND;
        command.network.ssid = data.ssid;
        command.network.locked_band = data.lockedBand;
        this.makeIpcRequest(command);
        return this._ipcObserverService.addSubscription(
            command.network.command
        );
    }

    discoverPtzDevices(): Observable<any> {
        const command = CAMERA_MANAGER.GET_CAMERA_LIST;
        this.makeIpcRequest(command);
        return this._ipcObserverService.addSubscription(command.video.command);
    }

    getPTZCapabilities(path: string): Observable<any> {
        const command = CAMERA_MANAGER.GET_CAMERA_CAPABILITIES;
        command.video.path = path;
        this.makeIpcRequest(command);
        return this._ipcObserverService.addSubscription(command.video.command);
    }

    getPTZCurrenntPosition(path: string): Observable<any> {
        const command = CAMERA_MANAGER.GET_CAMERA_CURRENT_POSITION;
        command.video.path = path;
        this.makeIpcRequest(command);
        return this._ipcObserverService.addSubscription(command.video.command);
    }

    setAbsolutePanTiltZoom(
        pan: string | number,
        tilt: string | number,
        zoom: string | number,
        path: string
    ) {
        const command = CAMERA_MANAGER.CAMERA_PTZ;
        command.video.path = path;
        command.video.ptz_values.pan = pan as string;
        command.video.ptz_values.tilt = tilt as string;
        command.video.ptz_values.zoom = zoom as string;

        this.makeIpcRequest(command);
        return this._ipcObserverService.addSubscription(command.video.command);
    }

    // absoluteZoom(zoom): Observable<any> {
    //     // TODO
    //     return of({ zoom });
    // }

    /** end request/response commands */
}
