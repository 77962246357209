import { waitForAppUpdate } from '@/shared/storage/layout/layout.actions';
import {
    selectAppUpdate,
    selectIntervalToCheckAppUpdate,
} from '@/shared/storage/selectors';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { Store } from '@ngrx/store';
import { interval } from 'rxjs';
import { switchMap, withLatestFrom } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoggerService } from '@services/logger';
import { IpcService } from '@services/ipc';

@Injectable({ providedIn: 'root' })
export class CheckForUpdateService {
    waitForTriggerUpdateList = [
        'form',
        'video',
        'waiting-room',
        'converge-video',
    ];

    constructor(
        private _updates: SwUpdate,
        private _store: Store,
        private _loggerService: LoggerService,
        protected _router: Router,
        private _ipcService: IpcService
    ) {
        if (!environment.enabledAutoUpdate) {
            return;
        }
        this._store
            .select(selectIntervalToCheckAppUpdate)
            .pipe(
                switchMap((updateCheckInterval) =>
                    interval(updateCheckInterval)
                )
            )
            .subscribe(() => {
                this._updates.isEnabled && this._updates.checkForUpdate();
            });

        // Log current build time - Help to check auto update application
        console.log(
            `%c Current Version - ${environment['version']}`,
            `%c Current Build Time - ${environment['currentBuildTime']}`,
            `background: green;
                color: #FFF;
                font-size:16px;
                background-color: green;
                border: 2px blue solid;
                padding: 15px;`
        );
    }

    restartBrowserEveryDay() {
        const now = new Date();
        // should restart between 3-5 AM at a random time
        const hourToRestart = Math.floor(Math.random() * (5 - 3) + 3);
        const minutesToRestart = Math.floor(Math.random() * 60);
        const restartAt = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate() + 1,
            hourToRestart,
            minutesToRestart,
            0
        );

        console.log('Time: ', hourToRestart, ':', minutesToRestart);
        console.log('Date', restartAt);

        const timeToRestartInMS = restartAt.getTime() - now.getTime();

        setTimeout(() => {
            this._loggerService.log('NOVA_WEB_UPDATE_SERVICE', {
                details: {
                    functionCalled: 'init',
                    actionToPerform: 'restartBrowserEveryday',
                },
            });

            // if intake-form/video call/waiting room/converge video is in progress then we will wait for it to finish
            // check in every 60s to restart browser until intake-form/video call/waiting room/converge video is over
            this._store
                .select(selectAppUpdate)
                .pipe(
                    switchMap(() => interval(60 * 1000)),
                    withLatestFrom(this._store.select(selectAppUpdate))
                )
                .subscribe(([, appUpdate]) => {
                    if (!appUpdate.waitForAppUpdate) {
                        this._ipcService.restartBrowser();
                    } else {
                        this._loggerService.log('NOVA_WEB_UPDATE_SERVICE', {
                            details: {
                                updateResponse:
                                    'Delayed browser restart - on a form, in a call or waiting room',
                                waitForAppUpdate: appUpdate.waitForAppUpdate,
                            },
                        });
                    }
                });
        }, timeToRestartInMS);
    }

    init() {
        this._loggerService.log('NOVA_WEB_UPDATE_SERVICE', {
            details: {
                functionCalled: 'init',
                currentVersion: environment.version,
                enabledAutoUpdate: environment.enabledAutoUpdate,
            },
        });

        this.restartBrowserEveryDay();

        // this._updates.available.subscribe((event) => {
        //     const update = {
        //         currentVersion: event.available.hash.toString(),
        //         previousVersion: event.current.hash.toString(),
        //         isAppUpdateAvailable: true,
        //     } as IAppUpdate;
        //
        //     this._store.dispatch(setAppUpdateAvailable({ appUpdate: update }));
        //
        //     this._loggerService.log('NOVA_WEB_UPDATE_SERVICE', {
        //         details: {
        //             functionCalled: 'init',
        //             actionToPerform: 'updateWebApp',
        //             update,
        //         },
        //     });
        //
        //     // After having update then it will refresh application.
        //     // if video call/waiting room/converge video is  in progress then will be waiting to finish
        //     // check in every 60s to reload app until video call/waiting room/converge video is getting finish
        //     this._store
        //         .select(selectAppUpdate)
        //         .pipe(
        //             switchMap(() => interval(60 * 1000)),
        //             withLatestFrom(this._store.select(selectAppUpdate))
        //         )
        //         .subscribe(([, appUpdate]) => {
        //             if (
        //                 appUpdate.isAppUpdateAvailable &&
        //                 !appUpdate.waitForAppUpdate
        //             ) {
        //                 this._updates.activateUpdate().then(() => {
        //                     // clear the old cache since they are not needed
        //                     caches.keys().then((keys) => {
        //                         keys.filter(
        //                             (key) =>
        //                                 !key.includes(appUpdate.currentVersion)
        //                         ).forEach((key) => caches.delete(key));
        //                         window.location.reload();
        //                     });
        //                 });
        //             } else {
        //                 this._loggerService.log('NOVA_WEB_UPDATE_SERVICE', {
        //                     details: {
        //                         updateResponse:
        //                             'Delayed - in a call or waiting room',
        //                         isUpdateAvailable:
        //                             appUpdate.isAppUpdateAvailable,
        //                         waitForAppUpdate: appUpdate.waitForAppUpdate,
        //                     },
        //                 });
        //             }
        //         });
        // });
        //
        this._router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                const wListUrl = this.waitForTriggerUpdateList.filter(
                    (wList) => {
                        return event.url.includes(wList);
                    }
                );
                this._store.dispatch(
                    waitForAppUpdate({
                        waitForAppUpdate: wListUrl.length > 0,
                    })
                );
            }
        });
    }
}
