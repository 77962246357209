import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class BrowserWindowService {
    constructor(private _window: Window) {}

    public refresh(): void {
        this._window.location.reload();
    }

    public refreshWithDelay(delay = 0): void {
        setTimeout(() => {
            this.refresh();
        }, delay);
    }

    public getHref(): string {
        return this._window.location.href;
    }
}
