<div
    class="flex items-center rel"
    *ngIf="battery !== null && (battery?.type !== undefined || battery?.tabletBatteryLevel !== undefined)"
>
    <h6
        *ngIf="showPercentage && battery && battery.kits && battery.kits.length"
        class="m-b0 m-r1 battery-text cursor-pointer"
        (click)="showBatteryDetails()"
        data-pw="header_percentage"
    >
        {{ battery.level }}%
    </h6>
    <app-battery-level
        *ngIf="showBatteryIcon && batteryStatus$ | async as batteryRef"
        class="cursor-pointer"
        (click)="showBatteryDetails()"
        [level]="batteryRef.level"
        [acPowerConnected]="batteryRef.acPowerConnected"
        [kits]="batteryRef && batteryRef.kits && batteryRef.kits.length"
    ></app-battery-level>
</div>

<app-battery-details
    *ngIf="battery && battery.type === 'PANDORA'"
    [hidden]="detailsHidden"
    (onHide)="onHide()"
></app-battery-details>
