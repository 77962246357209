<div class="flex justify-center menu-call">
    <i class="material-icons icon-link" [ngClass]="{ 'white-text': isTVKit }" #menuEl>more_vert</i>
    <aw-menu
        [groups]="group"
        dropdownClasses="aw-menu aw-wide"
        [commonBlock]="menuEl"
        (select)="onSelect($event)"
        showAside="left"
    >
    </aw-menu>
</div>
