import { INetworkListResponse } from '@/shared/interfaces';
import { selectNetworkState } from '@/shared/storage/selectors/network.selector';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';

@Component({
    selector: 'network-indicator',
    templateUrl: './network-indicator.component.html',
})
export class NetworkIndicatorComponent implements OnInit, OnDestroy {
    private _networkChangeSub: Subscription;
    public wifiSignal: number;
    public isWifiConnected: boolean;
    public isEthernetConnected: boolean;
    constructor(private _store: Store) {}

    ngOnInit(): void {
        this.processNetworkStatusData();
    }

    processNetworkStatusData() {
        this._networkChangeSub = this._store
            .select(selectNetworkState)
            .pipe(
                filter((networkState) => networkState !== null),
                distinctUntilChanged()
            )
            .subscribe((networkStateData: INetworkListResponse) => {
                console.log('network', networkStateData);
                const {
                    device_list: deviceList = [],
                    active_wifi_network: activeWifiNetwork = {
                        active: false,
                        signal: 0,
                    },
                } = networkStateData;
                if (activeWifiNetwork) {
                    const { active, signal } = activeWifiNetwork;
                    if (active) {
                        this.wifiSignal = signal;
                        this.isWifiConnected = true;
                    } else {
                        this.isWifiConnected = false;
                    }
                }

                if (deviceList) {
                    deviceList.forEach((device) => {
                        const { general } = device;
                        if (general && general.type === 'ETHERNET') {
                            if (general.state === 'CONNECTED') {
                                this.isEthernetConnected = true;
                                /* If both wifi and ethernet is connected, then display ethernet icon */
                                this.isWifiConnected = false;
                                return;
                            } else {
                                this.isEthernetConnected = false;
                            }
                        }
                    });
                }
            });
    }

    ngOnDestroy(): void {
        if (this._networkChangeSub) {
            this._networkChangeSub.unsubscribe();
        }
    }
}
