import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IpcService } from '@shared/services/ipc/ipc.service';
import { LoggerService } from '@shared/services/logger/logger.service';
import { SessionService } from '../session/session.service';
import { SUB_SYSTEM_TYPES } from '@/shared/constants/system-command';
import { BrowserWindowService } from '../browser-window/browser-window.service';
import { IDeviceInfo } from '@/shared/interfaces';

@Injectable({ providedIn: 'root' })
export class DeviceService {
    constructor(
        private _ipcService: IpcService,
        private _loggerService: LoggerService,
        private _sessionService: SessionService,
        private _browserWindowService: BrowserWindowService
    ) {}

    public rollback(rollbackOs: boolean, rollbackApp: boolean): void {
        if (rollbackOs) {
            this._ipcService.requestRollback(SUB_SYSTEM_TYPES.OPERATING_SYSTEM);
        }
        if (rollbackApp) {
            this._ipcService.requestRollback(SUB_SYSTEM_TYPES.APPLICATION);
        }
    }
    public restart(): void {
        this._ipcService.restartDevice();
        this._browserWindowService.refreshWithDelay(100);
    }

    public factoryDefault(): void {
        this._ipcService.requestFactoryDefault();
        this._ipcService.requestReboot();
        this._browserWindowService.refreshWithDelay(100);
    }

    public turnOff(): void {
        this._ipcService.requestLedRingOff();
        this._ipcService.shutdownDevice();
    }

    public restartAndReboot(): void {
        this._ipcService.restartApp();
        this._ipcService.requestReboot();
        this._browserWindowService.refreshWithDelay(100);
    }

    public sendLogs(data: any): void {
        this._loggerService.log(data);
    }

    public requestDeviceInfo(): Observable<IDeviceInfo> {
        return this._ipcService.requestSystemInfo();
    }

    public disconnectDevice(): void {
        //TO DO: disconnectDevice() from LocalService -- 26/08/21 - Need to TEST it and remove comment
        try {
            this._sessionService.deviceInstance.onCall = false;
        } catch (err) {
            this.sendLogs(err);
        }
    }
}
