/* eslint-disable @typescript-eslint/no-explicit-any */

import { createAction, props } from '@ngrx/store';
import { IActiveModal, IActiveToast, IAppUpdate } from './layout.reducer';

const OPEN_MODAL = 'OPEN_MODAL';
const CLOSE_MODAL = 'CLOSE_MODAL';
const CLOSE_ALL_MODAL = 'CLOSE_ALL_MODAL';
const ADD_ACTIVE_MODAL = 'ADD_ACTIVE_MODAL';
const REMOVE_ACTIVE_MODAL = 'REMOVE_ACTIVE_MODAL';
const SHOW_TOAST = 'SHOW_TOAST';
const ADD_ACTIVE_TOAST = 'ADD_ACTIVE_TOAST';
const REMOVE_ACTIVE_TOAST = 'REMOVE_ACTIVE_TOAST';
const REJOIN_CONVERGE_CALL = 'REJOIN_CONVERGE_CALL';
const UPDATE_SETTINGS_MODAL_STATUS = 'UPDATE_SETTINGS_MODAL_STATUS';
const UPDATE_ACTIVE_TAB_SETTINGS_MODAL = 'UPDATE_ACTIVE_TAB_SETTINGS_MODAL';
const SET_APP_UPDATE = 'SET_APP_UPDATE';
const SET_APP_UPDATE_AVAILABLE = 'SET_APP_UPDATE_AVAILABLE';
const SET_INTERVAL_TO_CHECK_APP_UPDATE = 'SET_INTERVAL_TO_CHECK_APP_UPDATE';
const WAIT_FOR_APP_UPDATE = 'WAIT_FOR_APP_UPDATE';
const NAVIGATE_TO_MAIN = 'NAVIGATE_TO_MAIN';
const SET_LOADING_SERVICES_LINES = 'SET_LOADING_SERVICES_LINES';
const CLOSE_ACTIVE_TOAST = 'CLOSE_ACTIVE_TOAST';

export const openModal = createAction(
    OPEN_MODAL,
    props<{ modal: IActiveModal }>()
);

export const closeModal = createAction(
    CLOSE_MODAL,
    props<{ modalId: string }>()
);

export const closeAllModal = createAction(CLOSE_ALL_MODAL);

export const addActiveModal = createAction(
    ADD_ACTIVE_MODAL,
    props<{ modal: IActiveModal }>()
);

export const removeActiveModal = createAction(
    REMOVE_ACTIVE_MODAL,
    props<{ modal: IActiveModal }>()
);

export const updateSettingModalStatus = createAction(
    UPDATE_SETTINGS_MODAL_STATUS,
    props<{ isOpenSettingModal: boolean }>()
);

export const updateActiveTabSettingModal = createAction(
    UPDATE_ACTIVE_TAB_SETTINGS_MODAL,
    props<{ activeTabSettingsModal: string }>()
);

export const showToast = createAction(
    SHOW_TOAST,
    props<{ toast: IActiveToast }>()
);

export const addActiveToast = createAction(
    ADD_ACTIVE_TOAST,
    props<{ toast: IActiveToast }>()
);

export const removeActiveToast = createAction(
    REMOVE_ACTIVE_TOAST,
    props<{ toast: IActiveToast }>()
);
export const rejoinConvergeCall = createAction(REJOIN_CONVERGE_CALL);

export const setAppUpdateAvailable = createAction(
    SET_APP_UPDATE_AVAILABLE,
    props<{ appUpdate: IAppUpdate }>()
);

export const setAppUpdate = createAction(
    SET_APP_UPDATE,
    props<{ appUpdate: IAppUpdate }>()
);

export const setIntervalToCheckAppUpdate = createAction(
    SET_INTERVAL_TO_CHECK_APP_UPDATE,
    props<{ interval: number }>()
);
export const waitForAppUpdate = createAction(
    WAIT_FOR_APP_UPDATE,
    props<{ waitForAppUpdate: boolean }>()
);

export const navigateToMain = createAction(NAVIGATE_TO_MAIN);
export const setLoadingServicesLines = createAction(
    SET_LOADING_SERVICES_LINES,
    props<{ loading: boolean }>()
);

export const closeActiveToast = createAction(CLOSE_ACTIVE_TOAST);

export type LayoutActions =
    | typeof openModal
    | typeof closeModal
    | typeof closeAllModal
    | typeof addActiveModal
    | typeof removeActiveModal
    | typeof updateSettingModalStatus
    | typeof showToast
    | typeof addActiveToast
    | typeof removeActiveToast
    | typeof rejoinConvergeCall
    | typeof setAppUpdateAvailable
    | typeof setAppUpdate
    | typeof setIntervalToCheckAppUpdate
    | typeof waitForAppUpdate
    | typeof navigateToMain
    | typeof setLoadingServicesLines
    | typeof closeActiveToast;
