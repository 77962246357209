export const BAND_CONFIGURATION = [
    {
        value: 'BAND_AUTO',
        displayText: 'Auto',
        dataPw: 'wifi_band_auto'
    },
    {
        value: 'BAND_5G',
        displayText: '5 GHz',
        dataPw: 'wifi_band_5ghz'
    },
    {
        value: 'BAND_2G',
        displayText: '2.4 GHz',
        dataPw: 'wifi_band_24ghz'
    },
];
