import { Injectable, OnDestroy } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { IpcService } from '../services/ipc';
import { SessionService } from '../services/session';
import { StorageService } from '../services/storage';
import { FleetHandlerService } from '../services/fleet';
import { LoggerService } from '../services/logger/logger.service';
import { MetricService } from '../services/metrics/metrics.service';
import { IBatteryStatus } from '../interfaces';
import { PTZAxisService } from '../services/ptz/ptz-axis.service';
import { PTZService } from '../services/ptz/ptz.service';
import { FECC_DEVICES, FECC_DEVICE_NAMES } from '@constants';
import { first } from 'rxjs/operators';

const CRITICAL_BATTERY_ALERT_VOLUME = 50;

@Injectable({ providedIn: 'root' })
export class DeviceBase implements OnDestroy {
    private _audioDevices = [];
    protected _usbDevices = [];
    protected _feccCameraSelected = false;
    protected _standbyState = false;
    protected _criticalBatteryState = false;
    private _onCall = false;
    protected _subscription: Subscription[] = [];
    public type: string;
    private _status: any;
    private _turnOffOnDisconnect = false;
    private _pollRenumerateDeviceRetry = 0;
    constructor(
        protected _fleetHandlerService: FleetHandlerService,
        protected _ipcService: IpcService,
        protected _sessionService: SessionService,
        protected _metricService: MetricService,
        protected _loggerService: LoggerService,
        protected _storageService: StorageService,
        protected _ptzAxisService: PTZAxisService,
        protected _ptzService: PTZService
    ) {
        this._subscription.push(
            this._storageService.subscribeOnConfigChange(() => {
                this.onConfigUpdate();
            }) as Subscription
        );
    }
    ngOnDestroy(): void {
        this._subscription.forEach((s) => s.unsubscribe());
    }

    init(): void {
        this._ipcService.requestStopWatchDog();
        this._ipcService.requestAudioControls();
        this._ipcService.requestUsbList();
        this._ipcService.requestNetworkStatus();
        // this._ipcService.requestNetworkList();
        this._ipcService.requestGetHostName();

        this._subscription.push(
            interval(60 * 1000).subscribe(() => {
                this._ipcService.requestNetworkStatus();
            })
        ); // 1 minute
        this._loggerService.info('deviceBase.onInit', '');
    }

    onConfigUpdate(): void {
        this.setCodecVersion();
    }

    setCodecVersion(): void {
        // do nothing. Will override in child class
    }

    setWorksurfaceLights(): void {
        // do nothing. Will override in C500
    }

    set audioDevices(data) {
        this._audioDevices = data;
    }

    get audioDevices(): any {
        return this._audioDevices;
    }

    set usbDevices(data) {
        this._usbDevices = data;
    }

    get feccCameraSelected(): boolean {
        return this._feccCameraSelected;
    }

    setMicGain(defaultDevice): void {
        this._ipcService.setMicGain({
            cardName: defaultDevice.card_name,
            ctrlName: defaultDevice.ctrl_name,
            deviceType: defaultDevice.device_type,
            volume: defaultDevice.volume,
        });
    }

    setSpeakerGain(defaultDevice): void {
        this._ipcService.setSpeakerGain({
            cardName: defaultDevice.card_name,
            ctrlName: defaultDevice.ctrl_name,
            deviceType: defaultDevice.device_type,
            volume: defaultDevice.volume,
        });
    }

    setCriticalBatteryState(state: boolean, batteryLevel?: number) {
        if (state) {
            this._criticalBatteryState = true;
            if (batteryLevel) {
                this.setSpeakerGain({
                    volume: batteryLevel,
                });
            } else {
                this.setSpeakerGain({
                    volume: CRITICAL_BATTERY_ALERT_VOLUME,
                });
            }
        } else {
            if (this._criticalBatteryState) {
                this.setSpeakerGain({
                    volume: this._sessionService.getConfig()
                        .AudioOutputDefaultVolume,
                });
                this._criticalBatteryState = false;
            }
        }
    }

    getCriticalBatteryState() {
        return this._criticalBatteryState;
    }

    getAudioControls() {
        return this._ipcService.getAudioControls();
    }

    requestAudioControls() {
        this._ipcService.requestAudioControls();
    }

    setPeripheralGain(peripheralGainData): void {
        this._ipcService.setPeripheralGain(peripheralGainData);
    }

    reenumerateDevice() {
        this._pollRenumerateDeviceRetry = 0;
        let feccCamera = null;
        for (const feccDevice of FECC_DEVICES) {
            feccCamera = this._usbDevices.find(
                (d) =>
                    feccDevice.name === FECC_DEVICE_NAMES.MINRRAY_CAMERA &&
                    feccDevice.productId === d.pid &&
                    feccDevice.vendorId === d.vid &&
                    feccDevice.v4lName.indexOf(d.product) > -1
            );
            if (feccCamera) {
                const { usb_version: usbVersion = '' } = feccCamera || {};
                if (usbVersion === '2.0.0') {
                    this.pollRenumerateDevices(feccCamera);
                }
                break;
            }
        }
    }

    pollRenumerateDevices(feccCamera) {
        this._pollRenumerateDeviceRetry++;
        this._ipcService
            .reenumerateDevice(feccCamera)
            .pipe(first())
            .subscribe((response) => {
                const { success } = response;
                if (!success) {
                    if (this._pollRenumerateDeviceRetry < 4) {
                        this.pollRenumerateDevices(feccCamera);
                    }
                }
            });
    }

    shutdownDevice(): void {
        this._ipcService.shutdownDevice();
    }

    set standbyState(state) {
        this._standbyState = state;
    }

    get standbyState(): any {
        return this._standbyState;
    }

    set onCall(value) {
        this._onCall = value;
    }

    get onCall(): any {
        return this._onCall;
    }

    sendPTZCommand(data: {
        command?: string;
        operation: any;
        level?: number;
        w?: any;
        h?: any;
    }) {
        if (data.operation === 'panTiltCommand') {
            data.w = 100;
            data.h = 100;
            return this._fleetHandlerService.sendCommand(
                data,
                'v1/apgar/camera/pan_tilt_command'
            );
        } else if (data.operation === 'relativeZoom') {
            return this._fleetHandlerService.sendCommand(
                data,
                'v1/apgar/camera/set_zoom_level'
            );
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    handleBatteryResponse(response: any): IBatteryStatus {
        return null;
    }

    getDefaultAudioInput(): any {
        return null;
    }

    getDefaultAudioOutput(): any {
        return null;
    }

    set deviceStatus(status) {
        this._status = status;
    }

    get deviceStatus(): any {
        return this._status;
    }

    set turnOffOnDisconnect(status: boolean) {
        this._turnOffOnDisconnect = status;
    }

    get turnOffOnDisconnect(): boolean {
        return this._turnOffOnDisconnect;
    }
}
