import { Action, createReducer, createSelector, on } from '@ngrx/store';
import * as WaitingRoomActions from './waiting-room-actions';
import { IWaitingRoomState, IServiceLineResponse } from '@shared/interfaces';

export const waitingRoomInitialState: IWaitingRoomState = {
    isCancelStatus: false,
    serviceLine: null,
    visit: null,
    intakeData: null,
    caseId: null,
    providerId: null,
    genderOptions: null,
    practise: null,
    caseNumber: null,
};

const reducer = createReducer(
    waitingRoomInitialState,
    on(WaitingRoomActions.setCancelStatus, (state, payload) => ({
        ...state,
        isCancelStatus: payload.isCancelStatus,
    })),

    on(WaitingRoomActions.setServiceLine, (state, payload) => ({
        ...state,
        serviceLine: payload.serviceLine,
    })),

    on(WaitingRoomActions.setVisit, (state, payload) => ({
        ...state,
        visit: payload.visit,
    })),

    on(WaitingRoomActions.setGenderOptions, (state, payload) => ({
        ...state,
        genderOptions: payload.genderOptions,
    })),

    on(WaitingRoomActions.setProviderId, (state, payload) => ({
        ...state,
        providerId: payload.providerId,
    })),

    on(WaitingRoomActions.setIntakeData, (state, payload) => ({
        ...state,
        intakeData: payload.intakeData,
    })),

    on(WaitingRoomActions.updateServiceLine, (state, payload) => ({
        ...state,
        serviceLine: { ...state.serviceLine, ...payload.serviceLine },
    })),

    on(WaitingRoomActions.updateIntakeData, (state, payload) => ({
        ...state,
        intakeData: { ...state.intakeData, ...payload.intakeData },
    })),

    on(WaitingRoomActions.setCaseId, (state, payload) => ({
        ...state,
        caseId: payload.caseId,
    })),

    on(WaitingRoomActions.setCaseInfo, (state, payload) => ({
        ...state,
        caseId: payload.caseId,
        caseNumber: payload.caseNumber,
    })),

    on(WaitingRoomActions.setPractise, (state, payload) => ({
        ...state,
        practise: payload.practise,
    }))
);

export function WaitingRoomReducer(
    state: IWaitingRoomState | undefined,
    action: Action
): IWaitingRoomState {
    return reducer(state, action);
}

export const selectWaitingRoomState = (state: Storage) => state.waitingRoom;

export const selectIsCancelStatus = createSelector(
    selectWaitingRoomState,
    (state: IWaitingRoomState) => state.isCancelStatus
);

export const selectServiceLine = createSelector(
    selectWaitingRoomState,
    (state: IWaitingRoomState) => state.serviceLine
);

export const selectLeaveCaseOpen = createSelector(
    selectServiceLine,
    (state: IServiceLineResponse) => state.leave_case_open
);

export const selectGenderOptions = createSelector(
    selectWaitingRoomState,
    (state: IWaitingRoomState) => state.genderOptions
);

export const selectIntakeData = createSelector(
    selectWaitingRoomState,
    (state: IWaitingRoomState) => state.intakeData
);

export const selectCaseId = createSelector(
    selectWaitingRoomState,
    (state: IWaitingRoomState) => state.caseId
);

export const selectCaseNumber = createSelector(
    selectWaitingRoomState,
    (state: IWaitingRoomState) => state.caseNumber
);

export const selectVisit = createSelector(
    selectWaitingRoomState,
    (state: IWaitingRoomState) => state.visit
);

export const selectPractise = createSelector(
    selectWaitingRoomState,
    (state: IWaitingRoomState) => state.practise
);

export const selectProviderId = createSelector(
    selectWaitingRoomState,
    (state: IWaitingRoomState) => state.providerId
);
