import { IServiceLine } from '@/shared/interfaces';
import { CaseService } from '@/shared/services/case/case.service';
import { TrackEventDataKeys } from '@/shared/services/statistic-collector/static-collector-defined-data';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SnackbarService } from '@aw-hospital/aw-components-lib/src/services/snackbar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { LEAVE_CASE_OPEN_QUEUE, MAX_SNACKBARS } from '@shared/constants';
import * as fromCaseActions from '@shared/storage/case/case.actions';
import * as fromLayoutActions from '@shared/storage/layout/layout.actions';
import * as fromWaitingRoomActions from '@shared/storage/waiting-room/waiting-room-actions';
import * as fromWaitingRoomReducers from '@shared/storage/waiting-room/waiting-room-reducer';
import { IActiveModal } from '@shared/storage/layout/layout.reducer';
import { of } from 'rxjs';
import {
    filter,
    first,
    map,
    switchMap,
    tap,
    withLatestFrom,
} from 'rxjs/operators';
import { StatisticCollector } from '@shared/services/statistic-collector/statistic-collector.service';
import { LoggerService } from '@services/logger/logger.service';

@Injectable()
export class CaseEffects {
    constructor(
        private _actions$: Actions,
        private _store: Store,
        private _caseService: CaseService,
        private _router: Router,
        private _snackbarService: SnackbarService,
        private _statisticCollector: StatisticCollector,
        private _loggerService: LoggerService
    ) {
        this._snackbarService.setMaxVisible(
            MAX_SNACKBARS,
            LEAVE_CASE_OPEN_QUEUE
        );
    }

    createCase$ = createEffect(() =>
        this._actions$.pipe(
            ofType(fromCaseActions.createCase),
            tap(() =>
                this._router
                    .navigateByUrl('waiting-room')
                    .then((response: boolean) => {
                        this._loggerService.log('CREATE_CASE_EFFECT', {
                            details: {
                                waitingRoomRedirectSuccess: response,
                            },
                        });
                    })
            ),
            switchMap(({ intakeData }: { intakeData: IServiceLine }) =>
                this._caseService.createCase(intakeData)
            ),
            switchMap(({ case_id, case_number }) =>
                of(
                    fromWaitingRoomActions.setCaseInfo({
                        caseId: case_id,
                        caseNumber: case_number,
                    })
                )
            )
        )
    );

    confirmCancelCase$ = createEffect(() =>
        this._actions$.pipe(
            ofType(fromCaseActions.confirmCancelCase),
            switchMap(() =>
                of(
                    fromLayoutActions.openModal({
                        modal: this._caseService
                            .getConfirmCancelCaseModal()
                            .setProp(
                                'afterClosed',
                                this._afterCancelCaseModalClosed.bind(this)
                            ) as IActiveModal,
                    })
                )
            ),
            tap(() => {
                this._statisticCollector.trackEvent(
                    TrackEventDataKeys.InitiateCancelCase
                );
            })
        )
    );

    cancelCase$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(fromCaseActions.cancelCase),
                switchMap(() =>
                    this._store
                        .select(fromWaitingRoomReducers.selectServiceLine)
                        .pipe(first())
                ),
                // TODO it's not clear do we need this just before canceling the case
                map((serviceLine: IServiceLine) => ({
                    ...serviceLine,
                    is_cancelling: true,
                })),
                tap((serviceLine: IServiceLine) =>
                    this._store.dispatch(
                        fromWaitingRoomActions.updateServiceLine({ serviceLine })
                    )
                ),
                withLatestFrom(
                    this._store.select(fromWaitingRoomReducers.selectCaseId),
                    this._store.select(fromWaitingRoomReducers.selectCaseNumber)
                ),
                filter(([serviceLine, caseId]) => serviceLine && caseId),
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                tap(([_, __, caseNumber]) =>
                    this._snackbarService.show(
                        this._caseService.getCancelCaseInfoSnackbar(caseNumber)
                    )
                ),
                switchMap(([serviceLine, caseId]) =>
                    this._caseService.cancelCase(
                        caseId,
                        serviceLine.workflow_id
                    )
                )
            ),
        { dispatch: false }
    );

    discardCancelCase$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(fromCaseActions.discardCancelCase),
                tap(() => {
                    this._statisticCollector.trackEvent(
                        TrackEventDataKeys.DiscardCase
                    );
                }),
                switchMap(() =>
                    this._store
                        .select(fromWaitingRoomReducers.selectServiceLine)
                        .pipe(first())
                ),
                filter((serviceLine: IServiceLine) => serviceLine.is_transfer),
                switchMap(() =>
                    of(this._router.navigateByUrl('waiting-room-next'))
                )
            ),
        { dispatch: false }
    );

    confirmLeaveCaseOpen$ = createEffect(() =>
        this._actions$.pipe(
            ofType(fromCaseActions.confirmLeaveCaseOpen),
            switchMap(() =>
                of(
                    fromLayoutActions.openModal({
                        modal: this._caseService
                            .getConfirmLeaveCaseOpenModal()
                            .setProp(
                                'afterClosed',
                                this._afterLeaveCaseOpenModalClosed.bind(this)
                            ) as IActiveModal,
                    })
                )
            )
        )
    );

    leaveCaseOpen$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(fromCaseActions.leaveCaseOpen),
                tap(() => this._store.dispatch(fromLayoutActions.navigateToMain())),
                // TODO add analytics call side effect to save event?
                withLatestFrom(this._store.select(fromWaitingRoomReducers.selectCaseNumber)),
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                tap(([_, caseNumber]) =>
                    this._snackbarService.show(
                        this._caseService.getLeaveCaseOpenSuccessSnackbar(
                            caseNumber
                        )
                    )
                )
            ),
        { dispatch: false }
    );

    private _afterCancelCaseModalClosed(confirmed: boolean): void {
        if (!confirmed) {
            this._store.dispatch(fromCaseActions.discardCancelCase());
            return;
        }

        this._store.dispatch(fromLayoutActions.navigateToMain());
        this._store.dispatch(fromCaseActions.cancelCase());
        this._statisticCollector.trackEvent(TrackEventDataKeys.CancelCase);
    }

    private _afterLeaveCaseOpenModalClosed(confirmed: boolean): void {
        if (!confirmed) {
            return;
        }

        this._store.dispatch(fromCaseActions.leaveCaseOpen());
    }
}
