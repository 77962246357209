import { Component, OnDestroy, Input, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { IBatteryStatus } from '@shared/interfaces';
import { selectBatteryState } from '@/shared/storage/selectors/battery.selectors';
import { SessionService } from '@/shared/services/session';
import { EndpointTypes } from '@shared/enums';

@Component({
    selector: 'battery-manager',
    templateUrl: './battery-manager.component.html',
    providers: [Store],
})
export class BatteryManagerComponent implements OnDestroy {
    @Input() showPercentage = true;
    @Input() showBatteryIcon = true;
    @Input() enableDetails = false;

    public battery: IBatteryStatus;
    public isConnented = false;
    public pathClassRequired = false;
    public batteryClass = 'icon icon_battery';
    batteryStatus$: Observable<IBatteryStatus>;

    private _subscriptions: Subscription[] = [];
    detailsHidden = true;

    constructor(
        private _store: Store,
        private _cdr: ChangeDetectorRef,
        private _sessionService: SessionService
    ) {
        this.batteryStatus$ = this._store.select(selectBatteryState);
        this.updateBatteryPercentage();
    }

    updateBatteryPercentage(): void {
        this._subscriptions.push(
            this._store
                .select(selectBatteryState)
                .subscribe((batteryState: IBatteryStatus) => {
                    this.battery = batteryState;
                    this._cdr.markForCheck();
                })
        );
    }

    showBatteryDetails() {
        if (
            this.enableDetails &&
            this._sessionService.getConfig().endpoint_type_id ===
                EndpointTypes.C500
        ) {
            this.detailsHidden = false;
        }
    }

    onHide() {
        this.detailsHidden = true;
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach(
            (subscription: Subscription) =>
                subscription && subscription.unsubscribe()
        );
    }
}
