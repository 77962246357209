<div class="neutral2 z-depth-1" *ngIf="batteryStatus" [ngClass]="{ hidden: hidden }">
    <ng-container *ngIf="batteryStatus">
        <header class="flex justify-between">
            <h5>Battery Details</h5>
            <span
                *ngIf="
                    (this.batteryStatus.acPowerConnected || !this.batteryStatus.acPowerConnected) &&
                    this.batteryStatus.kits.length
                "
                >{{ batteryStatus.level }}%</span
            >
            <span
                *ngIf="this.batteryStatus.acPowerConnected && !this.batteryStatus.kits.length"
                class="power-connected flex"
                >Power Connected <span class="material-icons icon iz-24 material-symbols-outlined"> power </span></span
            >
        </header>
        <section>
            <div class="kits-details">
                <div class="kit" *ngFor="let battery of batteries; index as i">
                    <span class="kit-number">{{ battery.id }}</span>
                    <app-battery-level
                        [ngClass]="{ 'm-l1': battery.acPowerConnected }"
                        [level]="battery.level > 95 ? 100 : battery.level"
                        [kits]="batteries.length"
                        [acPowerConnected]="battery.acPowerConnected"
                    ></app-battery-level>
                    <span class="kit-number m-l1">{{
                        battery.level ? (battery.level > 95 ? '100%' : battery.level + '%') : 'N/A'
                    }}</span>
                </div>
            </div>
            <p>
                Battery kits:
                {{
                    batteryStatus && batteryStatus.kits && batteryStatus.kits.length > maxBatteryKits
                        ? maxBatteryKits
                        : batteryStatus && batteryStatus.kits
                        ? batteryStatus.kits.length
                        : 0
                }}
                of {{ maxBatteryKits }}
            </p>
        </section>
        <footer class="neutral5-text">
            Your device supports extended battery kits. Contact your IT admin or Amwell representative to learn more.
        </footer>
    </ng-container>
    <div class="backdrop" (click)="hide()" [ngClass]="{ hidden: hidden }"></div>
</div>
