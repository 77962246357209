import { IFeccPosition } from '@/shared/interfaces/fecc-position/fecc-position.interface';
import { Action, createReducer, on } from '@ngrx/store';
import * as fromFeccPositionActions from './fecc-position.actions';

const feccPositionInitialState: { [key: string]: IFeccPosition } = {};

const reducer = createReducer(
    feccPositionInitialState,
    on(fromFeccPositionActions.selectFeccDevice, (state, payload) => ({
        ...state,
        [payload.codec]: {
            ...payload.feccConfig,
        },
    }))
);

export function FeccPoisitionReducer(
    state: { [key: string]: IFeccPosition },
    action: Action
): { [key: string]: IFeccPosition } {
    return reducer(state, action);
}
