import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

const THEMES = ['nova-web-clinical-theme', 'nova-web-converge-theme'];

@Injectable({ providedIn: 'root' })
export class ThemeSwitcher {
    constructor(@Inject(DOCUMENT) private document: Document) {
        this.setNovaClinicalTheme();
    }

    switchTheme(theme: string) {
        this.removeClassExcept(theme);
        this.addClass(theme);
    }

    setNovaClinicalTheme() {
        this.switchTheme(THEMES[0]);
    }

    setNovaConvergeTheme() {
        this.switchTheme(THEMES[1]);
    }

    private addClass(className) {
        this.document.body.classList.add(className);
    }

    private removeClass(className) {
        this.document.body.classList.remove(className);
    }

    private removeClassExcept(className) {
        THEMES.filter((theme) => theme !== className).forEach((className) =>
            this.removeClass(className)
        );
    }
}
