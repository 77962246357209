import { ConfirmModalType } from '@/shared/interfaces/modals/confirm-modal-type.enum';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IConfirmModalData } from '@shared/interfaces';

@Component({
    selector: 'confirm-modal',
    templateUrl: './confirm-modal.component.html',
    styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent {
    public static dialogName = 'ConfirmModalComponent';
    public title: string;
    public icon: string;
    public body: string;
    public cancelText: string;
    public confirmText: string;
    public data: IConfirmModalData;
    public type: ConfirmModalType;

    constructor(
        @Inject(MAT_DIALOG_DATA) public modalData: IConfirmModalData,
        public dialogRef: MatDialogRef<ConfirmModalComponent>
    ) {
        const defaultData = {
            title: 'Are you sure?',
            body: '<p>Please confirm your action<p>',
            cancelText: 'Cancel',
            confirmText: 'Confirm',
            primaryBtnStyle: 'btn-danger',
            type: ConfirmModalType.Default,
        };
        this.data = Object.assign({}, defaultData, modalData);
        this.type = this.data.type;
    }

    get isWaitingRoom(): boolean {
        return this.type === ConfirmModalType.WaitingRoom;
    }
}
