import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@guards/auth';
import {
    EmptyDashboardLayout,
    EmptyLayoutComponent,
    FullLayoutComponent,
    LightLayoutComponent,
    TVKitLayoutComponent,
} from '@core/layouts';
import { NoNetworkComponent } from '@/settings/no-network/no-network.component';

const routes: Routes = [
    {
        path: 'lost-connection',
        component: NoNetworkComponent,
    },
    {
        path: '',
        component: FullLayoutComponent,
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('../main/main.module').then((m) => m.MainModule),
    },
    {
        path: '',
        component: FullLayoutComponent,
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('../ocg-provider/ocg-provider.module').then(
                (m) => m.OcgProviderModule
            ),
    },
    {
        path: 'form',
        component: FullLayoutComponent,
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('../intake-form/intake-form.module').then(
                (m) => m.IntakeFormModule
            ),
    },
    {
        path: 'video',
        component: EmptyLayoutComponent,
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('../video/video.module').then((m) => m.VideoModule),
    },
    {
        path: 'tvkit-video',
        component: EmptyLayoutComponent,
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('../tvkit-video/tvkit-video.module').then(
                (m) => m.TVKitVideoModule
            ),
    },
    {
        path: 'empty-dashboard',
        component: EmptyDashboardLayout,
        canActivate: [AuthGuard],
    },
    {
        path: 'intmhomescreen',
        component: TVKitLayoutComponent,
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('../tvkit/tvkit.module').then((m) => m.TVKitModule),
    },
    {
        path: 'lock',
        component: EmptyLayoutComponent,
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('../lock-screen/lock-screen.module').then(
                (m) => m.LockScreenModule
            ),
    },
    {
        path: 'error-generic',
        component: EmptyLayoutComponent,
        loadChildren: () =>
            import('../error-generic/error-generic.module').then(
                (m) => m.ErrorGenericModule
            ),
    },
    {
        path: 'waiting-room',
        component: FullLayoutComponent,
        loadChildren: () =>
            import('../waiting-room/waiting-room.module').then(
                (m) => m.WaitingRoomModule
            ),
    },
    {
        path: 'converge-video',
        component: LightLayoutComponent,
        loadChildren: () =>
            import('../converge/converge.module').then((m) => m.ConvergeModule),
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class CoreRoutingModule {}
