import { Location } from '@angular/common';
import {
    Component,
    ContentChild,
    ElementRef,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { LoggerService } from '@services/logger';
import { IServiceLine } from '@/shared/interfaces';

@Component({
    selector: 'sticky-footer',
    templateUrl: './sticky-footer.component.html',
})
export class StickyFooterComponent {
    @Input() cancelDisabled = false;
    @Input() nextDisabled = false;
    @Input() previousDisabled = false;
    @Input() isNextProgressButton = false;
    @Input() cancelText = 'Cancel';
    @Input() isCancelFlat = false;

    @Output() nextCase = new EventEmitter();
    @Output() cancelCase = new EventEmitter();

    @ContentChild('externalControls') externalControlsRef: ElementRef;

    // public serviceLine;
    serviceLineName: string;
    serviceLineTypeId: string;

    constructor(
        private _location: Location,
        private _loggerService: LoggerService
    ) {}

    @Input() set serviceLine(serviceLine: IServiceLine) {
        const { name = '', service_line_type_id: serviceLineTypeId = '' } =
            serviceLine || {};
        this.serviceLineName = name;
        this.serviceLineTypeId = serviceLineTypeId;
    }

    cancel(): void {
        this.cancelCase.emit();
    }

    previous(): void {
        this._loggerService.log('CASE_FOOTER', {
            details: {
                functionCalled: 'previous',
                actionToPerform: 'navigateToPreviousBrowserLocation',
            },
        });
        this._location.back();
    }

    next(): void {
        if (this.nextDisabled) {
            return;
        }

        this.nextCase.emit();
    }
}
