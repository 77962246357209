const AVOID_ENDLESS_LOOP = 1000;
const DEFAULT_BOTTOM_MARGIN = 5;

export class KeyboardService {
    public static req = 0;
    public static startTransitionHandling(): void {
        if (this.req === 0) {
            document
                .querySelector('.keyboard')
                ?.addEventListener('transitionend', (e) =>
                    this.handleKeyboardTransition(e)
                );
        }
        this.req++;
    }

    public static stopTransitionHandling(): void {
        if (this.req === 1) {
            document
                .querySelector('.keyboard')
                ?.removeEventListener('transitionend', (e) =>
                    this.handleKeyboardTransition(e)
                );
        }
        this.req = Math.max(0, this.req - 1);
    }

    public static handleKeyboardTransition(e) {
        if (e.propertyName !== 'max-height') {
            return;
        }

        Array.from(
            document.querySelectorAll<HTMLElement>('.dynamic-keyboard-div')
        )
            .filter(
                ({ offsetParent, style }) =>
                    offsetParent || style.height !== '0px'
            )
            .forEach((dyndiv) =>
                this.__updateDynDivHeight(e.target.clientHeight, dyndiv)
            );
    }

    private static __updateDynDivHeight(height, dyndiv) {
        let wrapper = document.querySelector<HTMLElement>('.aw-tab-content');
        if (!wrapper) {
            const wrappers = document.querySelectorAll<HTMLElement>(
                '.dynamic-keyboard-wrapper'
            );
            wrapper = wrappers.length ? wrappers[wrappers.length - 1] : null;
        }

        const bottomMargin = Number(
            document.activeElement.getAttribute('margin-bottom') ||
                DEFAULT_BOTTOM_MARGIN
        );

        if (height) {
            const calcDiff = (h) =>
                document.activeElement.getBoundingClientRect().bottom -
                (window.innerHeight - h) +
                bottomMargin;
            for (
                let i = 0;
                calcDiff(height) >= 0 && i < AVOID_ENDLESS_LOOP;
                i += 1
            ) {
                // handling sticky jumps
                dyndiv.style.height = `${i}px`;
                wrapper.scrollTop += 1;
            }
        } else {
            dyndiv.style.height = '0px';
        }
    }
}
