import { FeccPosition } from '@/shared/enums';
import { createAction, props } from '@ngrx/store';

const SET_PTZ_FECC_POSITION_PRIVACY = 'SET_PTZ_FECC_POSITION_PRIVACY';
const SET_PTZ_FECC_POSITION_HOME = 'SET_PTZ_FECC_POSITION_HOME';
const SET_PTZ_FECC_POSITION_SUCCESS = 'SET_PTZ_FECC_POSITION_SUCCESS';
const NEW_FECC_DEVICE_ATTACHED = 'NEW_FECC_DEVICE_ATTACHED';
const CAMERA_CHANGED = 'CAMERA_CHANGED';

export const setPtzFeccPositionHome = createAction(
    SET_PTZ_FECC_POSITION_HOME,
    (force?: boolean) => ({
        position: FeccPosition.Home,
        force: force ?? false,
    })
);
export const setPtzFeccPositionPrivacy = createAction(
    SET_PTZ_FECC_POSITION_PRIVACY,
    (force?: boolean) => ({
        position: FeccPosition.Privacy,
        force: force ?? false,
    })
);
export const setPtzFeccPositionSuccess = createAction(
    SET_PTZ_FECC_POSITION_SUCCESS,
    props<{ position: FeccPosition }>()
);
export const newFeccDeviceAttached = createAction(NEW_FECC_DEVICE_ATTACHED);
export const cameraChanged = createAction(CAMERA_CHANGED);
