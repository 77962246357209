import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { ERROR_MESSAGES } from '@/shared/constants';
import { IGenericError } from '@/shared/interfaces/error/generic-error.interface';
import { LoggerService } from '@/shared/services/logger';
import { selectIsFleetConnected } from '@/shared/storage/selectors';
import { navigateToMain } from '@shared/storage/layout/layout.actions';
import { MatDialog } from '@angular/material/dialog';
import { RollbackDialogComponent } from '@/core/components/dialogs/rollback-dialog/rollback-dialog.component';
import { RestartDialogComponent } from '@/core/components/dialogs/restart-dialog.component';
import { FactoryDefaultDialogComponent } from '@/core/components/dialogs/factory-default-dialog.component';
import { TurnOffDialogComponent } from '@/core/components/dialogs/turn-off-dialog.component';
import { DEFAULT_DIALOGS_PANEL_CLASS } from '@/core/components/dialogs/dialogs-static.data';
import { Router } from '@angular/router';

@Component({
    selector: 'error-generic',
    templateUrl: './error-generic.component.html',
})
export class ErrorGenericPageComponent implements OnInit, OnDestroy {
    errorMessage: string;
    errorIcon = 'icon-no-results-on-provider-sdk-query';
    errorSubText: string;
    isFleetApiOffline = false;
    private _subs: Subscription[] = [];

    constructor(
        private _location: Location,
        private _loggerService: LoggerService,
        private _matDialog: MatDialog,
        private _store: Store,
        private _router: Router
    ) {
        this._subs.push(
            this._store
                .select(selectIsFleetConnected)
                .pipe(distinctUntilChanged())
                .subscribe(
                    (isFleetConnected: boolean) =>
                        (this.isFleetApiOffline = !isFleetConnected)
                )
        );
    }

    ngOnInit(): void {
        const errorObject: IGenericError = this._location.getState();
        this.errorMessage = errorObject.errorType || null;
        switch (errorObject.errorType) {
            case ERROR_MESSAGES.VIDEO_CALL_ERROR:
                this.errorSubText = ERROR_MESSAGES.VIDEO_CALL_ERROR_MESSAGE;
                break;
            case ERROR_MESSAGES.AMWELL_SERVICE_NOT_REACHABLE:
                this.errorSubText = ERROR_MESSAGES.CUSTOM_DATA_FAIL_SUB_TEXT;
                break;
            default:
                this.errorSubText = ERROR_MESSAGES.STANDARD_FAIL_SUB_TEXT;
                break;
        }
        this._loggerService.error(
            errorObject.errorType,
            errorObject.errorStack || {}
        );
    }

    ngOnDestroy() {
        this._subs.forEach((s) => s?.unsubscribe());
    }

    goToDashboard() {
        this._store.dispatch(navigateToMain());
    }

    restart() {
        this._matDialog.open(
            RestartDialogComponent,
            DEFAULT_DIALOGS_PANEL_CLASS
        );
    }

    factoryDefault(): void {
        this._matDialog.open(
            FactoryDefaultDialogComponent,
            DEFAULT_DIALOGS_PANEL_CLASS
        );
    }

    requestRollback(): void {
        this._matDialog.open(
            RollbackDialogComponent,
            DEFAULT_DIALOGS_PANEL_CLASS
        );
    }

    turnOff(): void {
        this._matDialog.open(
            TurnOffDialogComponent,
            DEFAULT_DIALOGS_PANEL_CLASS
        );
    }

    networkSettings(): void {
        this._router.navigateByUrl('/lost-connection');
    }
}
