export const FECC_DEVICE_NAMES = {
    BOLIN_201: 'Bolin_201',
    BOLIN_202: 'Bolin_202',
    PTZ_OPTICS_CAMERA: 'PTZOptics Camera',
    SONY_CAMERA: 'Sony Camera',
    MINRRAY_CAMERA: 'Minrray Camera',
    EPTZ_CAMERA: 'EPTZ Camera',
};

export const FECC_DEVICE_V4LNAME = {
    BOLIN_201: 'USB 3.0 Video Camera',
    BOLIN_202: 'USB 3.0 Video Camera',
    PTZ_OPTICS_CAMERA: 'PTZOptics',
    SONY_CAMERA: 'SRG-120DU',
    MINRRAY_CAMERA: 'Cyt-FX4',
    MINRRAY_CAMERA_20X: 'minrray20',
    MINRRAY_CAMERA_10X: 'minrray10',
    MINRRAY_CAMERA_WEST_BRIDGE: 'WestBridge ',
    EPTZ_CAMERA: 'See3CAM_CU135',
    AXIS_IP_CAMERA: 'AXIS',
};

export const FECC_DEVICES = [
    {
        name: FECC_DEVICE_NAMES.BOLIN_201,
        v4lName: FECC_DEVICE_V4LNAME.BOLIN_201,
        vendorId: '0x2cb3',
        productId: '0x0201',
        codecVersion: 'vid_2cb3&pid_0201',
        privacy: '-90,-30,1',
        home: '0,0,1',
        displayName: 'Bolin 10x PTZ Camera',
        label: '2cb3:0201',
    }, // Bolin_201
    {
        name: FECC_DEVICE_NAMES.BOLIN_202,
        v4lName: FECC_DEVICE_V4LNAME.BOLIN_202,
        vendorId: '0x2cb3',
        productId: '0x0202',
        codecVersion: 'vid_2cb3&pid_0202',
        privacy: '-90,-30,1',
        home: '0,0,1',
        displayName: 'Bolin 10x PTZ Camera',
        label: '2cb3:0202',
    }, // Bolin_202
    {
        name: FECC_DEVICE_NAMES.PTZ_OPTICS_CAMERA,
        v4lName: FECC_DEVICE_V4LNAME.PTZ_OPTICS_CAMERA,
        vendorId: '0x2e7e',
        productId: '0x0600',
        codecVersion: 'vid_2e7e&pid_0600',
        privacy: '-90,-30,1',
        home: '0,0,1',
        displayName: 'PTZOptics Camera',
        label: '2e7e:0600',
    }, // PTZOptics Camera
    {
        name: FECC_DEVICE_NAMES.SONY_CAMERA,
        v4lName: FECC_DEVICE_V4LNAME.SONY_CAMERA,
        vendorId: '0x054c',
        productId: '0x0b7f',
        codecVersion: 'vid_054c&pid_0b7f',
        privacy: '-4550,-1280,0',
        home: '0,0,0',
        displayName: 'Sony 12x PTZ Camera',
        label: '054c:0b7f',
    }, // Sony Camera
    {
        name: FECC_DEVICE_NAMES.MINRRAY_CAMERA,
        v4lName: FECC_DEVICE_V4LNAME.MINRRAY_CAMERA,
        vendorId: '0x04b4',
        productId: '0x00f9',
        codecVersion: 'vid_04b4&pid_00f9',
        privacy: '-91,-30,0',
        home: '0,0,0',
        displayName: '20x PTZ Camera',
        label: 'Cyt-FX4 (04b4:00f9)',
    }, // Minrray Camera old v4l name as USB 3.0
    {
        name: FECC_DEVICE_NAMES.MINRRAY_CAMERA,
        v4lName: FECC_DEVICE_V4LNAME.MINRRAY_CAMERA,
        vendorId: '0x04b4',
        productId: '0x04f2',
        codecVersion: 'vid_04b4&pid_04f2',
        privacy: '-91,-30,0',
        home: '0,0,0',
        displayName: '20x PTZ Camera',
        label: 'Cyt-FX4 (04b4:04f2)',
    }, // Minrray Camera old v4l name as USB 2.0
    {
        name: FECC_DEVICE_NAMES.MINRRAY_CAMERA,
        v4lName: FECC_DEVICE_V4LNAME.MINRRAY_CAMERA,
        vendorId: '0x0408',
        productId: '0x2070',
        codecVersion: 'vid_0408&pid_2070',
        privacy: '-91,-30,0',
        home: '0,0,0',
        displayName: '20x PTZ Camera',
        label: 'Cyt-FX4 (0408:2070)',
    }, // Minrray Camera old v4l name when listed as usb 2.0.0
    {
        name: FECC_DEVICE_NAMES.MINRRAY_CAMERA,
        v4lName: FECC_DEVICE_V4LNAME.MINRRAY_CAMERA,
        vendorId: '0x0408',
        productId: '0x7020',
        codecVersion: 'vid_0408&pid_7020',
        privacy: '-91,-30,0',
        home: '0,0,0',
        displayName: '20x PTZ Camera',
        label: 'Cyt-FX4 (0408:7020)',
    }, // Minrray Camera old v4l name when listed as usb 2.0.0
    {
        name: FECC_DEVICE_NAMES.MINRRAY_CAMERA,
        v4lName: FECC_DEVICE_V4LNAME.MINRRAY_CAMERA_20X,
        vendorId: '0x04b4',
        productId: '0x00f9',
        codecVersion: 'vid_04b4&pid_00f9',
        privacy: '-91,-30,0',
        home: '0,0,0',
        displayName: '20x PTZ Camera',
        label: 'minrray20 (04b4:00f9)',
    }, // Minrray Camera 20X when listed as usb 3.0.0
    {
        name: FECC_DEVICE_NAMES.MINRRAY_CAMERA,
        v4lName: FECC_DEVICE_V4LNAME.MINRRAY_CAMERA_20X,
        vendorId: '0x0408',
        productId: '0x2070',
        codecVersion: 'vid_0408&pid_2070',
        privacy: '-91,-30,0',
        home: '0,0,0',
        displayName: '20x PTZ Camera',
        label: 'minrray20 (0408:2070)',
    }, // Minrray Camera 20X when listed as usb 2.0.0
    {
        name: FECC_DEVICE_NAMES.MINRRAY_CAMERA,
        v4lName: FECC_DEVICE_V4LNAME.MINRRAY_CAMERA_20X,
        vendorId: '0x0408',
        productId: '0x7020',
        codecVersion: 'vid_0408&pid_7020',
        privacy: '-91,-30,0',
        home: '0,0,0',
        displayName: '20x PTZ Camera',
        label: 'minrray20 (0408:7020)',
    }, // Minrray Camera 20X when listed as usb 2.0.0
    {
        name: FECC_DEVICE_NAMES.MINRRAY_CAMERA,
        v4lName: FECC_DEVICE_V4LNAME.MINRRAY_CAMERA_10X,
        vendorId: '0x04b4',
        productId: '0x00f9',
        codecVersion: 'vid_04b4&pid_00f9_10',
        privacy: '-91,-30,0',
        home: '0,0,0',
        displayName: '10x PTZ Camera',
        label: 'minrray10 (04b4:00f9)',
    }, // Minrray Camera 10x when listed as usb 3.0.0
    {
        name: FECC_DEVICE_NAMES.MINRRAY_CAMERA,
        v4lName: FECC_DEVICE_V4LNAME.MINRRAY_CAMERA_10X,
        vendorId: '0x0408',
        productId: '0x2070',
        codecVersion: 'vid_0408&pid_2070_10',
        privacy: '-91,-30,0',
        home: '0,0,0',
        displayName: '10x PTZ Camera',
        label: 'minrray10 (0408:2070)',
    }, // Minrray Camera 10x when listed as usb 2.0.0
    {
        name: FECC_DEVICE_NAMES.MINRRAY_CAMERA,
        v4lName: FECC_DEVICE_V4LNAME.MINRRAY_CAMERA_10X,
        vendorId: '0x0408',
        productId: '0x7020',
        codecVersion: 'vid_0408&pid_7020_10',
        privacy: '-91,-30,0',
        home: '0,0,0',
        displayName: '10x PTZ Camera',
        label: 'minrray10 (0408:7020)',
    }, // Minrray Camera 10x when listed as usb 2.0.0
    {
        name: FECC_DEVICE_NAMES.EPTZ_CAMERA,
        v4lName: FECC_DEVICE_V4LNAME.EPTZ_CAMERA,
        vendorId: '0x2560',
        productId: '0xc1d1',
        codecVersion: 'vid_2560&pid_c1d1',
        privacy: '-91,-30,100',
        home: '0,0,100',
        displayName: '4x PTZ Camera (Internal)',
        label: 'See3CAM_CU135',
    },
    {
        name: FECC_DEVICE_NAMES.MINRRAY_CAMERA,
        v4lName: FECC_DEVICE_V4LNAME.MINRRAY_CAMERA_WEST_BRIDGE,
        vendorId: '0x04b4',
        productId: '0x00f3',
        codecVersion: 'vid_04b4&pid_00f3',
        privacy: '-91,-30,0',
        home: '0,0,0',
        displayName: '20x PTZ Camera',
        label: 'WestBridge',
    }, // Minrray Camera 10x when listed as usb 2.0.0
];
