import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { EndpointTypes } from '@/shared/enums';

import * as selectors from '@storage/selectors';
import { filter } from 'rxjs/operators';
import { UserService } from '@/shared/services/user';
import { IntmVideoService } from '@/shared/services/intmvideo';
import { VideoService } from '@/shared/services/video';

@Component({
    selector: 'caller',
    templateUrl: './caller.component.html',
})
export class CallerComponent implements OnInit {
    @Input() notAnswerLabel = false;

    public isActive = false;

    public callerTitle = 'Incoming Call';

    public callerInfo = {
        title: '',
        name: '',
        display_name: '',
        organization_name: null,
        profilePicture: '/assets/images/staff-provider-black.svg',
        url: '/assets/images/staff-provider-black.svg',
    };

    constructor(
        private _store: Store,
        private _userService: UserService,
        private _intmvideoService: IntmVideoService,
        private _videoService: VideoService
    ) {}

    ngOnInit(): void {
        this._store
            .select(selectors.selectEndpointByKey, { key: 'endpoint_type_id' })
            .pipe(filter((endpointTypeId) => !!endpointTypeId))
            .subscribe((endpointTypeId) => {
                const room =
                    endpointTypeId === EndpointTypes.ApgarCodec ||
                    endpointTypeId === EndpointTypes.C500
                        ? this._videoService.getRoom()
                        : this._intmvideoService.getRoom();
                if (room?.callerName) {
                    this.callerTitle = room.callerName;
                } else if (room?.caller_id) {
                    this.getUserInfo(room);
                }
            });
    }

    getUserInfo(room: { caller_id: string }) {
        this._userService.getUser(room.caller_id).subscribe((result) => {
            this.callerInfo = result;
            const displayName = this.callerInfo.display_name
                ? this.callerInfo.display_name
                : `${this.callerInfo.title || ''} ${this.callerInfo.name}`;

            this.callerInfo.profilePicture = this.callerInfo.url
                ? this.callerInfo.url
                : '/assets/images/staff-provider-black.svg';
            this.callerTitle = displayName;
        });
    }
}
