<div class="modal-content-wrapper">
    <div class="modal-heading p0">
        <div class="page-heading-simple gradient-horizontal-blue flex items-center justify-between">
            <h2 class="m-0 white-text settings-header" data-pw="setting_header">Settings</h2>
            <aw-button
                (onClick)="closeAnalytic()"
                automationValue="closeModalIcon"
                aria-label="Dismiss"
                styleClasses="icon-link white-text rel"
                [mat-dialog-close]="'Cross'"
                data-pw="setting_close"
            >
                <i class="material-icons">close</i>
            </aw-button>
        </div>
        <aw-tabs
            automationValue="settingsTab"
            styleClasses="fullwidth gradient-horizontal-blue settings-tabs"
            [activeTab]="activeTab"
            (activeTabChange)="activeTabChange($event)"
        >
            <aw-tab
                automationValue="tab{{ tab.key }}"
                *ngFor="let tab of settingsTabs | keyvalue : originalOrder"
                elementId="{{ tab.value }}"
                styleClasses="col s6 white-text"
                attr.data-pw="setting_{{ tab.key.toLowerCase() }}"
            >
                {{ tab.key }}
            </aw-tab>
        </aw-tabs>
    </div>
    <div class="modal-content p0 dynamic-keyboard-wrapper">
        <div class="modal-content-alt h100">
            <ng-container>
                <div *ngIf="activeTab === 'general'" automation="generalTab" class="w100">
                    <app-general-tab></app-general-tab>
                </div>
                <div *ngIf="activeTab === 'av'" automation="audioVideoTab" class="w100">
                    <app-audio-video-tab></app-audio-video-tab>
                </div>
                <div *ngIf="activeTab === 'connectivity'" automation="connectivityTab" class="w100">
                    <app-connectivity-tab></app-connectivity-tab>
                </div>
            </ng-container>
        </div>
        <div class="dynamic-keyboard-div"></div>
    </div>
</div>
