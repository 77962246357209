import { combineLatest, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { IDevice } from '@shared/interfaces';
import { SessionService } from '@shared/services/session/session.service';
import { Store } from '@ngrx/store';
import {
    selectConfig,
    selectMediaDevicesList,
    selectSelectedAudioInput,
    selectSelectedAudioOutput,
    selectSelectedVideoInput,
} from '@/shared/storage/selectors';
import { takeUntil, takeWhile } from 'rxjs/operators';
import { LogService } from '../log';
import { KIND_OF_MEDIA_DEVICE } from '@/shared/constants';
import { IpcService } from '../ipc';
import { StorageService } from '../storage';
import { Adapters, EndpointTypes } from '@/shared/enums';

@Injectable({ providedIn: 'root' })
export class MediaDevicesService {
    protected _devices: IDevice[] = [];
    // private devices$ = new Subject();

    private _defaultAudioOutput: IDevice;
    private _defaultAudioInput: IDevice;
    private _defaultVideoInput: IDevice;
    private _alive = true;

    public destroy$ = new Subject();

    constructor(
        protected _sessionService: SessionService,
        protected _store: Store,
        protected _logService: LogService,
        protected _ipcService: IpcService,
        private _storageService: StorageService
    ) {
        // TODO: it is just a boilerplate
        // should be done at AU-301
        // navigator.mediaDevices
        //     .enumerateDevices()
        //     .then((devices: MediaDeviceInfo[]) => {
        //         this._devices = devices;
        //         this.devices$.next(devices);
        //     });

        this._store
            .select(selectMediaDevicesList)
            .pipe(takeUntil(this.destroy$))
            .subscribe((devices) => {
                this._devices = devices;
            });

        combineLatest([
            this._store.select(selectSelectedAudioOutput),
            this._store.select(selectSelectedAudioInput),
            this._store.select(selectSelectedVideoInput),
        ])
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                ([
                    selectedAudioOutput,
                    selectedAudioInput,
                    selectedVideoInput,
                ]) => {
                    this._defaultAudioOutput = selectedAudioOutput;
                    this._defaultAudioInput = selectedAudioInput;
                    this._defaultVideoInput = selectedVideoInput;
                }
            );
        this._store
            .select(selectConfig)
            .pipe(takeWhile(() => this._alive))
            .subscribe((config) => {
                if (
                    !config ||
                    this.constructor.name !== MediaDevicesService.name ||
                    this._sessionService?.endpoint?.endpoint_type_id !==
                        EndpointTypes.C500
                ) {
                    return;
                }
                this._alive = false;
            });
    }

    get devicesPromise() {
        return this._store.select(selectMediaDevicesList);
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    getBuiltInAudioOutput(): IDevice {
        return this._devices.find((f) => f.defaultPlay);
    }

    getDevices(): Array<IDevice> {
        return this._devices;
    }

    audioInput(): Array<IDevice> {
        const devices = this._devices.filter((device) => {
            return (
                device.kind === KIND_OF_MEDIA_DEVICE.AUDIO_INPUT &&
                !device.isPeripheral
            );
        });
        console.log('PRINTING AUDIO INPUT DEVICES', devices);
        return devices;
    }

    audioOutput(): Array<IDevice> {
        const devices = this._devices.filter((device) => {
            if (
                this._sessionService?.endpoint?.endpoint_type_id ===
                EndpointTypes.ApgarCodec
            ) {
                return (
                    device.kind === KIND_OF_MEDIA_DEVICE.AUDIO_OUTPUT &&
                    device.label !== Adapters.AWing &&
                    !device.isPeripheral
                );
            } else {
                return (
                    device.kind === KIND_OF_MEDIA_DEVICE.AUDIO_OUTPUT &&
                    !device.isPeripheral
                );
            }
        });
        console.log('PRINTING AUDIO OUTPUT DEVICES', devices);
        return devices;
    }

    getCameraInput(): Array<IDevice> {
        return this._devices.filter((device) => {
            return (
                device.kind === KIND_OF_MEDIA_DEVICE.VIDEO_INPUT &&
                !device.isPeripheral
            );
        });
    }

    defaultVideoInput(): IDevice | { deviceId: number } {
        return this._defaultVideoInput;
        // let isExists;
        // const userSelectedVideoInput = this._sessionService.videoInput;
        // const videoInputs = this.getCameraInput();

        // if (videoInputs && videoInputs.length > 0) {
        //     isExists = videoInputs.find((items) => items.isFeccEnabled);

        //     if (userSelectedVideoInput) {
        //         isExists = videoInputs.find((device) => {
        //             return device.label.indexOf(userSelectedVideoInput) >= 0;
        //         });
        //     }

        //     return isExists || videoInputs[0];
        // }
        // return {
        //     deviceId: null,
        // };
    }

    defaultAudioOutput(): IDevice {
        return this._defaultAudioOutput;
        // const audioOutputs = this.audioOutput();
        // const userSelectedAudioOutput = this._sessionService.audioOutput;
        // const deviceOrder: Array<string | number> = ['Phnx MT202'];

        // if (userSelectedAudioOutput) {
        //     deviceOrder.push(userSelectedAudioOutput);
        // }

        // deviceOrder.push('PCM2704C');
        // deviceOrder.push('Built-in');

        // if (audioOutputs && audioOutputs.length > 0) {
        //     deviceOrder.push(audioOutputs[0].label);
        // }

        // for (const label of deviceOrder) {
        //     const item = audioOutputs.find((input) => {
        //         return input.label.indexOf(label as string) >= 0;
        //     });

        //     if (item) {
        //         return item;
        //     }
        // }
    }

    defaultAudioInput(): IDevice {
        return this._defaultAudioInput;
        // const audioInputs = this.audioInput();
        // const userSelectedAudioInput = this._sessionService.audioInput;
        // const deviceOrder: Array<string | number> = ['Phnx MT202'];
        // if (userSelectedAudioInput) {
        //     deviceOrder.push(userSelectedAudioInput);
        // }
        // deviceOrder.push('08bb:2912');
        // deviceOrder.push('PCM2912A');
        // deviceOrder.push('Built-in');

        // if (audioInputs && audioInputs.length > 0) {
        //     deviceOrder.push(audioInputs[0].label);
        // }

        // for (const label of deviceOrder) {
        //     const item = audioInputs.find((input) => {
        //         return input.label.indexOf(label as string) >= 0;
        //     });

        //     if (item) {
        //         return item;
        //     }
        // }
    }

    onAudioOutputChange(selectedDevice): IDevice {
        return this._devices.filter((device) => {
            return (
                device.kind === KIND_OF_MEDIA_DEVICE.AUDIO_OUTPUT &&
                !device.isPeripheral &&
                selectedDevice.deviceId === device.deviceId
            );
        })[0];
    }
}
